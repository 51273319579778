import { CalendarContextType } from "./types";
import { createContext } from "react";
import dayjs from "dayjs";

export const calendarContext = createContext<CalendarContextType>({
  handleGoNext: () => undefined,
  handleScrollNext: () => undefined,
  handleGoPrev: () => undefined,
  handleScrollPrev: () => undefined,
  handleGoToday: () => undefined,
  zoomIn: () => undefined,
  zoomOut: () => undefined,
  onClickNewRequest: () => undefined,
  changeZoom: (zoomLevel: number) => undefined,
  handleFilterData: () => undefined,
  updateTilesCoords: () => undefined,
  tilesCoords: [],
  zoom: 0,
  isNextZoom: false,
  isPrevZoom: false,
  date: dayjs(),
  isLoading: false,
  cols: 0,
  startDate: {
    hour: 0,
    dayName: "",
    dayOfMonth: 0,
    weekOfYear: 0,
    month: 0,
    monthName: "",
    isCurrentDay: false,
    isBusinessDay: false,
    year: 0
  },
  dayOfYear: 0,
  recordsThreshold: 0,
  config: {
    zoom: 0
  }
});
