import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Modal,
  TextField as MuiTextField,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  useAddVehicleAvailabilityMutation,
  useDeleteVehicleAvailabilityMutation,
  useGetAffectedBookingsLazyQuery,
  useRevokeAffectedBookingsMutation,
  useUpdateVehicleAvailabilityMutation,
  useVehicleTypesQueryQuery,
} from '../../../@generated/hooks';
import { useEffect, useState } from 'react';
import { useGetList, useNotify } from 'react-admin';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AvailabilityType } from '../../../@generated/schemas';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import enLocale from 'date-fns/locale/en-GB';
import styled from 'styled-components';
import { useTranslate } from '../../../locales';

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 24px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const RevokeTableCell = styled(TableCell)`
  fieldset legend {
    display: none;
  }
`;

export const AvailabilityDetailModal = (props: any) => {
  const { open, filter, refetch, newEvent, onChange, onClose } = props;
  const notify = useNotify();
  const { data: vehicleTypesData } = useVehicleTypesQueryQuery({
    fetchPolicy: 'no-cache',
  });
  const translate = useTranslate();
  const [addAvailability] = useAddVehicleAvailabilityMutation();
  const [updateAvailability] = useUpdateVehicleAvailabilityMutation();
  const [deleteAvailability] = useDeleteVehicleAvailabilityMutation();
  const [affectedBookings, setAffectedBookingsData] = useState([]);
  const [getAffectedBookings] = useGetAffectedBookingsLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const { data: sharedVehicleGroups } = useGetList('SharedVehicleGroup', {
    filter: {
      isPrivate: true,
      deletedAt: {
        equals: null,
      },
    },
    pagination: {
      page: 1,
      perPage: 1000,
    },
  });
  const [revokeAffectedBookings] = useRevokeAffectedBookingsMutation();

  const daysInRange = dayjs(newEvent.endDateTime).diff(
    dayjs(newEvent.startDateTime),
    'minutes',
  );

  useEffect(() => {
    async function initAffectedBookings() {
      if (open && newEvent.startDateTime && newEvent.endDateTime) {
        const response = await getAffectedBookings({
          variables: {
            vehicleId: newEvent.vehicleId,
            startDate: newEvent.startDateTime,
            endDate: newEvent.endDateTime,
          },
        });

        setAffectedBookingsData(response.data?.getAffectedBookings || []);
      }
    }
    initAffectedBookings();
  }, [open, newEvent.startDateTime, newEvent.endDateTime]);

  const isDisabledSaveEvent =
    !newEvent.type ||
    daysInRange < 0 ||
    !dayjs(newEvent.startDateTime).isValid() ||
    !dayjs(newEvent.endDateTime).isValid();

  useEffect(() => {
    if (newEvent) {
      if (newEvent.startDateTime && newEvent.endDateTime) {
        // for daily events its not possible to have more then X day in range IF
        if (
          newEvent.type === AvailabilityType.RegularDaily &&
          daysInRange > 24 * 60
        ) {
          onChange('type', undefined);
        } else if (
          newEvent.type === AvailabilityType.RegularWeekly &&
          daysInRange > 24 * 60 * 7
        ) {
          onChange('type', undefined);
        }
        // this gonna be really rare because it really depends on selected month
        else if (
          newEvent.type === AvailabilityType.RegularMonthly &&
          daysInRange > 24 * 60 * 28
        ) {
          onChange('type', undefined);
        }
      }
    }
  }, [newEvent.startDateTime, newEvent.endDateTime]);

  const showSaveError = () => {
    if (newEvent.type === AvailabilityType.Single) {
      notify(translate('admin.existsBookingInDateRange'), {
        type: 'warning',
      });
    } else {
      notify(translate('admin.alreadyBooked'), {
        type: 'warning',
      });
    }
  };

  const handleSubmitAvailability = async () => {
    const _affectedBookings = affectedBookings
      .filter((affectedBooking) => affectedBooking.revokeReason)
      .map((affectedBooking) => ({
        bookingId: affectedBooking.id,
        revokeReason: affectedBooking.revokeReason,
      }));

    if (_affectedBookings.length) {
      await revokeAffectedBookings({
        variables: {
          bookings: _affectedBookings,
        },
      });
    }

    try {
      if (newEvent.availabilityId) {
        await updateAvailability({
          variables: {
            ...newEvent,
            startDateTime: dayjs(newEvent.startDateTime).format(),
            endDateTime: dayjs(newEvent.endDateTime).format(),
          },
        });
      } else {
        await addAvailability({
          variables: newEvent,
        });
      }

      onClose();

      await refetch(filter);
    } catch (error) {
      console.error(error);
      showSaveError();
    }
  };

  const handleDeleteAvailability = async (hard = true) => {
    try {
      if (hard || newEvent.type === AvailabilityType.Single) {
        await deleteAvailability({
          variables: {
            availabilityId: newEvent.availabilityId,
          },
        });
      } else if (newEvent.type !== AvailabilityType.Single) {
        await addAvailability({
          variables: {
            ...newEvent,
            isAvailable: false,
          },
        });
      }

      setAffectedBookingsData([]);

      onClose();

      await refetch(filter);
    } catch (error) {
      console.error(error);
      showSaveError();
    }
  };

  const handleSetRevokeReason = (bookingId: string, revokeReason: string) => {
    setAffectedBookingsData(
      affectedBookings.map((booking) => {
        if (booking.id === bookingId) {
          return {
            ...booking,
            revokeReason,
          };
        }
        return booking;
      }),
    );
  };

  const handleExpireAvailability = async () => {
    try {
      await updateAvailability({
        variables: {
          availabilityId: newEvent.availabilityId,
          expiredAt: dayjs(newEvent.endDateTime).toDate(),
        },
      });
      onClose();

      await refetch(filter);
    } catch (error) {
      console.error(error);
      showSaveError();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalContent>
        <IconButton
          style={{ position: 'absolute', right: 18 }}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            {newEvent.availabilityId
              ? translate('admin.edit')
              : translate('admin.add')}{' '}
            {translate('admin.newAvailability')}
          </Typography>

          <FormRow style={{ marginBottom: 20 }}>
            <DateTimePicker
              format="DD.MM.YYYY HH:mm"
              ampm={false}
              label={translate('admin.startDateTime')}
              value={dayjs(newEvent.startDateTime)}
              onChange={(newValue) => {
                onChange('startDateTime', newValue);
              }}
            />
            <DateTimePicker
              ampm={false}
              format="DD.MM.YYYY HH:mm"
              label={translate('admin.endDateTime')}
              value={dayjs(newEvent.endDateTime)}
              onChange={(newValue) => onChange('endDateTime', newValue)}
            />
          </FormRow>

          <FormRow style={{ marginBottom: 20 }}>
            <FormControl style={{ marginBottom: 20 }} fullWidth>
              <FormLabel>{translate('admin.availability')}</FormLabel>
              <RadioGroup
                row
                value={newEvent.isAvailable}
                name="radio-buttons-isAvailable">
                <FormControlLabel
                  value={true}
                  onClick={() => onChange('isAvailable', true)}
                  control={<Radio />}
                  label={translate('admin.available')}
                />
                <FormControlLabel
                  value={false}
                  onClick={() => onChange('isAvailable', false)}
                  control={<Radio />}
                  label={translate('admin.notAvailable')}
                />
              </RadioGroup>
            </FormControl>

            <FormControl style={{ marginBottom: 20 }} fullWidth>
              <FormLabel>{translate('admin.sharedVehicleGroups')}</FormLabel>

              <Select
                multiple
                value={newEvent.sharedVehicleGroups || []}
                variant="outlined"
                size="small"
                label=""
                displayEmpty
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {(!selected.length ||
                      !newEvent.sharedVehicleGroups?.length) && (
                      <em>{translate('admin.public')}</em>
                    )}
                    {sharedVehicleGroups
                      .filter((svg) => selected.includes(svg.id))
                      .map((svg) => (
                        <Chip key={svg.id} label={svg.name} />
                      ))}
                  </Box>
                )}
                input={<OutlinedInput label={translate('admin.chip')} />}
                onChange={(event) => {
                  const checkReset = event.target.value.includes(null);
                  if (checkReset) {
                    onChange('sharedVehicleGroups', []);
                  } else {
                    onChange('sharedVehicleGroups', event.target.value);
                  }
                }}>
                <MenuItem value={null}>
                  <em>{translate('admin.publicSvg')}</em>
                </MenuItem>
                <ListSubheader>{translate('admin.privateSvg')}</ListSubheader>
                {sharedVehicleGroups?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox
                      checked={newEvent.sharedVehicleGroups.includes(item.id)}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormRow>

          <FormControl style={{ marginBottom: 20 }} fullWidth>
            <FormLabel>{translate('admin.typeOfEvent')}</FormLabel>
            <Select
              value={newEvent.type}
              variant="outlined"
              size="small"
              onChange={(event) => {
                onChange('type', event.target.value);
              }}>
              <MenuItem value={AvailabilityType.Single}>
                {translate('admin.single')}
              </MenuItem>
              <MenuItem
                value={AvailabilityType.RegularDaily}
                disabled={daysInRange > 24 * 60}>
                {translate('admin.daily')}
              </MenuItem>
              <MenuItem
                value={AvailabilityType.RegularWeekly}
                disabled={daysInRange > 24 * 60 * 7}>
                {translate('admin.weekly')}
              </MenuItem>
              <MenuItem
                value={AvailabilityType.RegularMonthly}
                disabled={daysInRange > 24 * 60 * 28}>
                {translate('admin.monthly')}
              </MenuItem>
            </Select>
          </FormControl>

          {!newEvent.availabilityId &&
            newEvent.type &&
            newEvent.type !== AvailabilityType.Single && (
              <FormControl style={{ marginBottom: 20 }} fullWidth>
                <FormLabel>
                  {translate('admin.expiresAt')}{' '}
                  <Switch
                    value="checkedA"
                    checked={newEvent.expiredAt}
                    onChange={() =>
                      onChange(
                        'expiredAt',
                        newEvent.expiredAt
                          ? null
                          : dayjs().add(7, 'days').toDate(),
                      )
                    }
                    inputProps={{ 'aria-label': 'Switch A' }}
                  />
                </FormLabel>
                <DateTimePicker
                  format="DD.MM.YYYY HH:mm"
                  ampm={false}
                  disabled={!newEvent.expiredAt}
                  value={newEvent.expiredAt ? dayjs(newEvent.expiredAt) : null}
                  onChange={(newValue) => {
                    onChange('expiredAt', newValue);
                  }}
                />
              </FormControl>
            )}

          <FormControl style={{ marginBottom: 20 }} fullWidth>
            <FormLabel>{translate('admin.comment')}</FormLabel>
            <MuiTextField
              autoFocus
              variant="outlined"
              size="small"
              value={newEvent.comment}
              onChange={(event) => onChange('comment', event.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </FormControl>

          {affectedBookings.length > 0 && (
            <Table
              sx={{ minWidth: 650 }}
              style={{ maxHeight: '5wh' }}
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{translate('admin.bookingHash')}</TableCell>
                  <TableCell>{translate('admin.status')}</TableCell>
                  <TableCell>{translate('admin.vehicle')}</TableCell>
                  <TableCell>{translate('admin.start')}</TableCell>
                  <TableCell>{translate('admin.end')}</TableCell>
                  <TableCell align="right" width="30%">
                    {translate('admin.revokeStatus')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {affectedBookings.map((booking) => (
                  <TableRow key={booking.id}>
                    <TableCell component="th" scope="row">
                      <a href={`/#/Booking/${booking.id}/show`} target="_blank">
                        {booking.bookingHash}
                      </a>
                    </TableCell>
                    <TableCell>
                      <Chip label={booking.bookingStatus} color="primary" />
                    </TableCell>
                    <TableCell>
                      <a
                        href={`/#/Vehicle/${booking.vehicle.id}`}
                        target="_blank">
                        {booking.vehicle.vin}
                      </a>
                    </TableCell>
                    <TableCell>
                      {dayjs(booking.startDate).format('DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      {dayjs(booking.endDate).format('DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <RevokeTableCell>
                      <FormControl size="small" fullWidth>
                        <InputLabel></InputLabel>
                        <Select
                          value={booking.revokeReason}
                          label="Revoke reason"
                          onChange={(event) =>
                            handleSetRevokeReason(
                              booking.id,
                              event.target.value,
                            )
                          }>
                          <MenuItem value={null}>
                            <em>{translate('admin.none')}</em>
                          </MenuItem>
                          {(vehicleTypesData?.revokeReasonTypes || []).map(
                            (item) => (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    </RevokeTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          <div style={{ marginTop: 20 }}>
            {newEvent.availabilityId && (
              <Button
                startIcon={<DeleteIcon />}
                variant="outlined"
                disabled={isDisabledSaveEvent}
                style={{ float: 'left', marginRight: 8 }}
                onClick={() => handleDeleteAvailability(false)}>
                {translate('admin.deleteToday')}
              </Button>
            )}
            {newEvent.availabilityId && (
              <Button
                startIcon={<DeleteIcon />}
                variant="outlined"
                disabled={isDisabledSaveEvent}
                style={{ float: 'left', marginRight: 8 }}
                onClick={handleExpireAvailability}>
                {translate('admin.deleteAll')}
              </Button>
            )}
            <Button
              variant="contained"
              disabled={isDisabledSaveEvent}
              onClick={handleSubmitAvailability}
              style={{ float: 'right' }}>
              {newEvent.availabilityId
                ? translate('admin.save')
                : translate('admin.add')}{' '}
              {translate('admin.event')}
            </Button>
          </div>
        </LocalizationProvider>
      </ModalContent>
    </Modal>
  );
};
