import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ListActions,
  TextField,
} from 'react-admin';

import { ChargingStationCluster as ChargingStationClusterType } from '../../../@generated/schemas';
import { Chip } from '@mui/material';
import CustomPagination from '../../../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import { useTranslate } from '../../../locales';

export const ChargingStationClusterList = () => {
  const translate = useTranslate();
  return (
    <List
      title={translate('admin.eveClusters')}
      actions={<ListActions hasCreate />}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          source="name"
          label={translate('admin.name')}
          render={(row: ChargingStationClusterType) =>
            JSON.parse(row.name || '{}')?.en ||
            JSON.parse(row.name || '{}')?.de ||
            JSON.parse(row.name || '{}')?.it ||
            JSON.parse(row.name || '{}')?.fr
          }
        />
        <TextField source="company.name" label={translate('admin.company')} />
        <FunctionField
          source="eves"
          label={translate('admin.eves')}
          render={(row: ChargingStationClusterType) => (
            <Chip label={row.chargingStations.length} />
          )}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment ChargingStationClusterFragment on ChargingStationCluster {
    company {
      id
      name
    }
  }
`;

export const ChargingStationCluster: ResourceView = {
  resource: 'ChargingStationCluster',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
