import { BookingDuration } from './durations';
import { BookingQuestions } from './bookingQuestions';

export const Booking: React.VFC = () => {
  return (
    <div>
      <BookingDuration />
      <br />
      <BookingQuestions />
    </div>
  );
};
