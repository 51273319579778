import { GlobalStyle, darkTheme, theme } from '../../styles';
import { StyledInnerWrapper, StyledOutsideWrapper } from './styles';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Calendar } from '../../components';
import CalendarProvider from '../../context/CalendarProvider';
import { Config } from '../../types/global';
import { SchedulerProps } from './types';
import { ThemeProvider } from 'styled-components';
import dayjs from 'dayjs';
import { outsideWrapperId } from '../../constants';

const GlobalStyleProxy: any = GlobalStyle;

const Scheduler = ({
  data,
  config,
  startDate,
  onRangeChange,
  onTileClick,
  onVehicleClick,
  onFilterData,
  onClearFilterData,
  onItemResize,
  onItemClick,
  onItemDrop,
  onItemCreate,
  onVehicleMapDetailClick,
  onClickNewRequest,
  isLoading,
}: SchedulerProps) => {
  const appConfig: Config = useMemo(
    () => ({
      zoom: 0,
      filterButtonState: 1,
      includeTakenHoursOnWeekendsInDayView: false,
      showTooltip: true,
      translations: undefined,
      ...config,
    }),
    [config],
  );

  const outsideWrapperRef = useRef<HTMLDivElement>(null);
  const [topBarWidth, setTopBarWidth] = useState(
    outsideWrapperRef.current?.clientWidth,
  );
  const defaultStartDate = useMemo(() => dayjs(startDate), [startDate]);
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>(
    appConfig.defaultTheme ?? 'light',
  );
  const toggleTheme = () => {
    themeMode === 'light' ? setThemeMode('dark') : setThemeMode('light');
  };

  const currentTheme = themeMode === 'light' ? theme : darkTheme;
  const customColors = appConfig.theme
    ? appConfig.theme[currentTheme.mode]
    : {};
  const mergedTheme = {
    ...currentTheme,
    colors: {
      ...currentTheme.colors,
      ...customColors,
    },
  };

  useEffect(() => {
    const handleResize = () => {
      if (outsideWrapperRef.current) {
        setTopBarWidth(outsideWrapperRef.current.clientWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!outsideWrapperRef.current) null;
  return (
    <>
      <GlobalStyleProxy />
      <ThemeProvider theme={mergedTheme}>
        <CalendarProvider
          data={data}
          isLoading={!!isLoading}
          config={appConfig}
          onClickNewRequest={onClickNewRequest}
          onRangeChange={onRangeChange}
          defaultStartDate={defaultStartDate}
          onFilterData={onFilterData}
          onClearFilterData={onClearFilterData}>
          <StyledOutsideWrapper
            showScroll={!!data.length}
            id={outsideWrapperId}
            ref={outsideWrapperRef}>
            <StyledInnerWrapper>
              <Calendar
                data={data}
                onTileClick={onTileClick}
                topBarWidth={topBarWidth ?? 0}
                onItemClick={onItemClick}
                onVehicleClick={onVehicleClick}
                onVehicleMapDetailClick={onVehicleMapDetailClick}
                toggleTheme={toggleTheme}
                onItemDrop={onItemDrop}
                onItemResize={onItemResize}
                onItemCreate={onItemCreate}
              />
            </StyledInnerWrapper>
          </StyledOutsideWrapper>
        </CalendarProvider>
      </ThemeProvider>
    </>
  );
};

export default Scheduler;
