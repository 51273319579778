import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  downloadCSV,
  useGetIdentity,
} from 'react-admin';
import { BookingStatus, UserRole } from '../../@generated/schemas';

import { Chip } from '@mui/material';
import CustomPagination from '../../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import dayjs from 'dayjs';
import formattedPrice from '../../libs/formattedPrice';
import { gql } from '@apollo/client';
import jsonExport from 'jsonexport/dist';
import mapEnumSelectInput from '../../libs/mapEnumSelectInput';
import { useTranslate } from '../../locales';

const QuickFilter = ({
  label,
}: {
  label: string;
  source?: string;
  defaultValue?: any;
  alwaysOn?: boolean;
}) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

export const BookingFilter = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  const defaultVehicleFilter = {
    deletedAt: {
      equals: null,
    },
    OR:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? [
            {
              user:
                identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
                  ? {
                      is: {
                        companyId: {
                          equals: identity?.companyId,
                        },
                      },
                    }
                  : {},
            },
            {
              companyId: {
                equals: identity?.companyId,
              },
            },
          ]
        : [],
  };

  const defaultUserFilter = {
    deletedAt: {
      equals: null,
    },
    companyId:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? { equals: identity?.companyId }
        : undefined,
  };

  return (
    <Filter {...props}>
      <TextInput label={translate('admin.search')} source="q" alwaysOn />
      <TextInput source="bookingHash" label={translate('admin.bookingHash')} />
      <DateTimeInput
        source="cancelDate_gte"
        label={translate('admin.cancelledFrom')}
      />
      <DateTimeInput
        source="cancelDate_lte"
        label={translate('admin.cancelledTo')}
      />
      <DateTimeInput
        source="adminChangeCommentAt_gte"
        label={translate('admin.adminChangedDateFrom')}
      />
      <SelectInput
        source="bookingStatus"
        translateChoice={false}
        label={translate('admin.bookingStatus')}
        choices={mapEnumSelectInput(BookingStatus)}
      />
      <ReferenceArrayInput
        source="vehicle_ids.is"
        reference="Vehicle"
        label={translate('admin.vehicles')}>
        <AutocompleteArrayInput
          label={translate('admin.vehicles')}
          optionText={(choice: any) =>
            `${choice.plateNumber} (${choice.name || choice?.vin})`
          }
          style={{ width: 300 }}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="user_ids.is"
        reference="User"
        label={translate('admin.users')}>
        <AutocompleteArrayInput
          label={translate('admin.users')}
          optionText={(choice: any) => choice?.email}
          style={{ width: 300 }}
        />
      </ReferenceArrayInput>
      <DateTimeInput
        source="startDate_gte"
        label={translate('admin.bookingStartDate')}
      />
      <DateTimeInput
        source="endDate_lte"
        label={translate('admin.bookingEndDate')}
      />
    </Filter>
  );
};

const formattedMileage = (record) => {
  return `${Number(
    (record.vehicleMileageOnEffectiveEnd ||
      record.vehicleMileageOnEffectiveStart ||
      0) -
      (record.vehicleMileageOnEffectiveStart ||
        record.vehicleMileageOnEffectiveEnd ||
        0),
  ).toFixed(1)} km`;
};

const customExporter = (items) => {
  const listForExport = items.map((row) => {
    const excessCost = row.calculation.endPrice?.costForDistance || 0;
    const additionalCost =
      parseFloat(
        formattedPrice(
          (row.calculation?.endPrice?.totalCostWithoutInsurance || 0) -
            (row.calculation?.totalCost ||
              row.calculation?.startPrice?.totalCostWithoutInsurance ||
              0),
          false,
        ),
      ) - excessCost;

    return {
      'Booking hash': row.bookingHash,
      'Vehicle VIN': row.vehicle.vin,
      'Vehicle Plate number': row.vehicle.plateNumber,
      'Start date': row.startDate
        ? dayjs(row.startDate).format('DD.MM. YYYY')
        : '',
      'Start time': row.startDate ? dayjs(row.startDate).format('HH:mm') : '',
      'End date': row.endDate ? dayjs(row.endDate).format('DD.MM. YYYY') : '',
      'End time': row.endDate ? dayjs(row.endDate).format('HH:mm') : '',
      'Effective start date': row.effectiveStartDate
        ? dayjs(row.effectiveStartDate).format('DD.MM. YYYY')
        : '',
      'Effective start time': row.effectiveStartDate
        ? dayjs(row.effectiveStartDate).format('HH:mm')
        : '',
      'Effective end date': row.effectiveEndDate
        ? dayjs(row.effectiveEndDate).format('DD.MM. YYYY')
        : '',
      'Effective end time': row.effectiveEndDate
        ? dayjs(row.effectiveEndDate).format('HH:mm')
        : '',
      'Start/end km': `${row.vehicleMileageOnEffectiveStart || 0}/${
        row.vehicleMileageOnEffectiveEnd || 0
      }`,
      'Driven km': formattedMileage(row),
      'Commision to owner': formattedPrice(
        (row.calculation?.endPrice?.totalCostWithoutInsurance ||
          row.calculation?.startPrice?.totalCostWithoutInsurance) *
          (row.commission / 100),
      ),
      User: row.user.email,
      'Insurance Name': row.vehicle.vehicleInsuranceType?.name,
      'Excess km': row.calculation.endPrice?.excessKm,
      'Subtotal rental costs': formattedPrice(
        row.calculation?.endPrice?.totalCostWithoutInsurance ||
          row.calculation?.startPrice?.totalCostWithoutInsurance,
      ),
      'Insurance costs': formattedPrice(
        row.calculation?.endPrice?.insuranceCost || 0,
      ),
      'Late fees': formattedPrice(
        excessCost > 0 || additionalCost > 0 ? additionalCost : 0,
      ),
      'Total costs': formattedPrice(row.effectiveBookingCost || 0),
    };
  });

  jsonExport(listForExport, { rowDelimiter: ';' }, (err, csv) =>
    downloadCSV(csv, 'Booking report'),
  );
};

export const BookingList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  const defaultFilter = {
    cancelDate: true,
    OR: [
      {
        user: {
          is: {
            companyId: {
              equals: identity?.companyId,
            },
          },
        },
      },
      {
        vehicle: {
          is: {
            OR: [
              {
                user: {
                  is: {
                    companyId: {
                      equals: identity?.companyId,
                    },
                  },
                },
              },
              {
                companyId: {
                  equals: identity?.companyId,
                },
              },
            ],
          },
        },
      },
    ],
  };

  if (identity?.userRole && identity?.userRole === UserRole.SuperAdmin) {
    delete defaultFilter.OR;
  }

  return (
    <List
      {...props}
      filter={defaultFilter}
      exporter={customExporter}
      filters={<BookingFilter />}
      sort={{ field: 'createdAt' }}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="bookingHash"
          label={translate('admin.bookingHash')}
        />
        <FunctionField
          source="vehicle.vin"
          label={translate('admin.vin')}
          render={(row) => (
            <Link to={`/Vehicle/${row.vehicle.id}`}>{row.vehicle.vin}</Link>
          )}
        />
        <FunctionField
          source="vehicle.plateNumber"
          label={translate('admin.plateNumber')}
          render={(row) => (
            <Link to={`/Vehicle/${row.vehicle.id}`}>
              {row.vehicle.plateNumber}
            </Link>
          )}
        />
        <FunctionField
          source="startDate"
          label={translate('admin.bookingColumn')}
          render={(record) => {
            return (
              <div>
                <div>
                  <b>{translate('admin.start')}:</b>
                </div>{' '}
                {dayjs(record.startDate).format('DD.MM. YYYY HH:mm')}
                <br />
                <div>
                  <b>{translate('admin.end')}:</b>
                </div>{' '}
                {dayjs(record.endDate).format('DD.MM. YYYY HH:mm')}
              </div>
            );
          }}
        />
        <FunctionField
          source="effectiveStartDate"
          label={translate('admin.effective')}
          render={(record) => {
            return (
              <div>
                {record.effectiveStartDate && (
                  <>
                    <div>
                      <b>{translate('admin.start')}:</b>
                    </div>{' '}
                    {dayjs(record.effectiveStartDate).format(
                      'DD.MM. YYYY HH:mm',
                    )}
                  </>
                )}
                <br />
                {record.effectiveEndDate && (
                  <>
                    <div>
                      <b>{translate('admin.end')}:</b>
                    </div>{' '}
                    {dayjs(record.effectiveEndDate).format('DD.MM. YYYY HH:mm')}
                  </>
                )}
              </div>
            );
          }}
        />
        <FunctionField
          source="mileage"
          sortable={false}
          label={translate('admin.drivenKm')}
          render={(record) => formattedMileage(record)}
        />
        <FunctionField
          label={translate('admin.bookingStatus')}
          render={(record) => {
            return <Chip label={record.bookingStatus} color="primary" />;
          }}
        />
        <FunctionField
          source="user.email"
          label={translate('admin.emailField')}
          render={(row) => (
            <Link to={`/User/${row.user.id}`}>{row.user.email}</Link>
          )}
        />
        <DateField
          source="createdAt"
          label={translate('admin.createdAt')}
          lang="en"
          showTime
        />
        <FunctionField
          label={translate('admin.effectiveBookingCost')}
          render={(record) => formattedPrice(record.effectiveBookingCost || 0)}
        />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const Booking: ResourceView = {
  resource: 'Booking',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment BookingFragment on Booking {
          bookingFuelPassword {
            id
            createdAt
          }
          sharedVehicleGroup {
            id
            name
            discount
            ownerBehavior
            isPrivate
            deletedAt
          }
          user {
            id
            firstName
            lastName
            email
          }
          vehicleLocation {
            id
            latitude
            longitude
            name
          }
          sharedVehicleGroup {
            id
            name
            discount
            ownerBehavior
            isPrivate
            deletedAt
          }
          vehicle {
            id
            name
            brandType {
              id
              name
            }
            modelType {
              id
              name
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment BookingFragment on Booking {
          vehicleLocation {
            id
            latitude
            longitude
            name
          }
          user {
            id
            firstName
            lastName
            email
          }
          calculation {
            startPrice {
              totalCost
              insuranceCost
              excessKm
              costForDistance
              costForDuration
              totalCostWithoutInsurance
            }
            endPrice {
              totalCost
              insuranceCost
              excessKm
              costForDistance
              costForDuration
              totalCostWithoutInsurance
            }
          }
          vehicle {
            id
            vin
            plateNumber
            vehicleInsuranceType {
              id
              name
            }
            modelType {
              id
              name
            }
            name
            brandType {
              id
              name
            }
          }
        }
      `,
    },
  },
};
