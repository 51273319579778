import { FC, useState } from 'react';
import { Tab, Typography } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';

import queryString from 'query-string';
import {
 useRecordContext
} from 'react-admin';
import { useTranslate } from '../../locales';

export const TABS = {
  basicInfo: 'basicInfo',
  bookingQuestions: 'bookingQuestions',
};

export const UserTabs: FC<{children: JSX.Element | JSX.Element[]}> = (props) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const [selectedTab, setTab] = useState<string>(
    (queryString.parse(window.location.search)?.tab ||
      TABS.basicInfo) as string,
  );

  const onChangeTab = (tabName: string) => {
    if (tabName) {
      setTab(tabName);
    }
  };

  return (
    <TabContext value={selectedTab}>
      <div style={{ position: 'relative' }}>
        <Typography variant="h5" style={{ marginBottom: 12 }}>
          {record?.id ? translate('admin.editUser') : translate('admin.createUser')}
        </Typography>

        <TabList
          defaultValue={selectedTab}
          onChange={(_, value) => onChangeTab(value)}
          aria-label="">
          <Tab label={translate('admin.basicInfo')} value={TABS.basicInfo} />
          {record?.id && (
            <Tab label={translate('admin.bookingQuestions')} value={TABS.bookingQuestions} />
          )}
        </TabList>
        {props.children}
      </div>
    </TabContext>
  );
};
