import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  FormDataConsumer,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  downloadCSV,
  useGetIdentity,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  FormGroup,
  Grid,
  ImageList,
  ImageListItem,
  ListItem,
  ListItemText,
  List as ListMaterial,
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
} from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import {
  SortOrder,
  VehicleDamageKind,
  VehicleDamageTime,
  VehicleDamageType,
  VehiclePhotoType,
} from '../../@generated/schemas';
import { cloneElement, useState } from 'react';
import {
  useCreateVehicleDamageCommentMutation,
  useUpdateVehicleDamageMutation,
  useVehicleCommentSuggestionsQuery,
  useVehicleDamageCommentsQuery,
  useVehiclePhotosQuery,
} from '../../@generated/hooks';

import CustomPagination from '../../CustomPagination';
import { DefaultLocation } from '../vehicles/locations';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { VehicleDamageCommentsDocument } from '../../@generated/hooks';
import { convertEnumFilterItems } from '../../libs/convertEnumFilterItems';
import dayjs from 'dayjs';
import getStoragePath from '../../libs/getStoragePath';
import { gql } from '@apollo/client';
import jsonExport from 'jsonexport/dist';
import { useTranslate } from '../../locales';

export const VehicleDamageEdit = (props: any) => {
  const refreshData = useRefresh();
  const translate = useTranslate();
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <Box style={{ width: '100%' }}>
          <Grid container spacing={8}>
            <Grid item md={6}>
              <FormGroup>
                <FormGroup row>
                  <TextInput
                    source="kind"
                    label={translate('admin.kind')}
                    style={{ flex: 1, marginRight: 6 }}
                  />
                  <TextInput
                    source="time"
                    label={translate('admin.time')}
                    style={{ flex: 1 }}
                  />
                </FormGroup>
                <TextInput
                  source="description"
                  label={translate('admin.description')}
                  style={{ flex: 1 }}
                />
                <FormGroup row>
                  <ReferenceField source="userId" reference="User">
                    <TextInput
                      source="email"
                      label={translate('admin.reportedBy')}
                      disabled
                      style={{ flex: 1 }}
                    />
                  </ReferenceField>
                  <DateTimeInput
                    source="createdAt"
                    label={translate('admin.reportedAt')}
                    style={{ flex: 1, maxWidth: '30%', marginLeft: 6 }}
                  />
                  <ReferenceField source="vehicleId" reference="Vehicle">
                    <TextInput
                      source="vin"
                      label={translate('admin.vehicle')}
                      disabled
                      style={{ flex: 1, marginLeft: 6 }}
                    />
                  </ReferenceField>
                </FormGroup>
                <FormGroup row>
                  <ReferenceField source="bookingId" reference="Booking">
                    <TextInput
                      source="bookingHash"
                      label={translate('admin.bookingHash')}
                      disabled
                      style={{ flex: 1 }}
                    />
                  </ReferenceField>
                  <ReferenceField source="bookingId" reference="Booking">
                    <FormDataConsumer>
                      {({ formData }) => {
                        const record = useRecordContext();

                        let status = 'Unknown status';
                        const isBeforeBooking = dayjs(
                          formData.createdAt,
                        ).isBefore(dayjs(record.effectiveStartDate));
                        const isAfterBooking = dayjs(
                          formData.createdAt,
                        ).isAfter(dayjs(record.effectiveEndDate));

                        console.log(isBeforeBooking, isAfterBooking);

                        if (!record.effectiveStartDate || isBeforeBooking) {
                          status = translate('admin.startBooking');
                        } else if (record.effectiveEndDate && isAfterBooking) {
                          status = translate('admin.endBooking');
                        } else if (!isBeforeBooking && !isAfterBooking) {
                          status = translate('admin.vehicleInUse');
                        }

                        return (
                          <Chip
                            label={`${translate(
                              'admin.bookingStatus',
                            )}: ${status}`}
                            style={{ marginTop: 16, marginLeft: 4 }}
                          />
                        );
                      }}
                    </FormDataConsumer>
                  </ReferenceField>
                </FormGroup>
                <BooleanInput
                  source="isPublic"
                  label={translate('admin.isPublic')}
                  style={{ flex: 1 }}
                />
                <BooleanInput
                  source="isResolved"
                  label={translate('admin.isResolved')}
                  style={{ flex: 1 }}
                />
              </FormGroup>
            </Grid>
            <Grid item md={6}>
              <FormDataConsumer>
                {() => {
                  const record = useRecordContext();
                  return (
                    <GoogleMap
                      mapContainerStyle={{
                        width: '100%',
                        height: 400,
                      }}
                      center={{
                        lat: record.latitude || DefaultLocation.latitude,
                        lng: record.longitude || DefaultLocation.longitude,
                      }}
                      zoom={8}>
                      {record.latitude && record.longitude && (
                        <Marker
                          position={{
                            lat: record.latitude,
                            lng: record.longitude,
                          }}
                        />
                      )}
                    </GoogleMap>
                  );
                }}
              </FormDataConsumer>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item md={6}>
              <FormDataConsumer>
                {() => {
                  const record = useRecordContext();
                  const { data } = useVehiclePhotosQuery({
                    variables: {
                      where: {
                        vehicleDamageId: {
                          contains: record.id as string,
                        },
                        type: {
                          not: {
                            equals: VehiclePhotoType.Gallery,
                          },
                        },
                      },
                    },
                  });
                  return (
                    <ImageList>
                      {data?.vehiclePhotos.map((item) => (
                        <ImageListItem key={item.id}>
                          <img
                            src={getStoragePath(`damages/${item.name}`)}
                            srcSet={getStoragePath(`damages/${item.name}`)}
                            alt={item.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  );
                }}
              </FormDataConsumer>
            </Grid>
            <Grid item md={6}>
              <FormDataConsumer>
                {() => {
                  const record = useRecordContext();
                  const { identity } = useGetIdentity();
                  const [comment, setComment] = useState<string>('');
                  const { data } = useVehicleDamageCommentsQuery({
                    variables: {
                      where: {
                        vehicleDamageId: {
                          contains: record.id as string,
                        },
                      },
                      orderBy: [
                        {
                          createdAt: SortOrder.Desc,
                        },
                      ],
                    },
                  });
                  const { data: suggestionsData } =
                    useVehicleCommentSuggestionsQuery();
                  const [addComment] = useCreateVehicleDamageCommentMutation();
                  const [updateVehicleDamage] =
                    useUpdateVehicleDamageMutation();

                  const handleSave = async (saveAndResolve: boolean) => {
                    await addComment({
                      variables: {
                        userId: `${identity?.id}`,
                        content: comment,
                        vehicleDamageId: record.id as string,
                      },
                      refetchQueries: [VehicleDamageCommentsDocument],
                    });

                    if (saveAndResolve) {
                      await updateVehicleDamage({
                        variables: {
                          where: {
                            id: record.id as string,
                          },
                          data: {
                            isResolved: {
                              set: true,
                            },
                          },
                        },
                      });
                      refreshData();
                    }

                    setComment('');
                  };

                  return (
                    <>
                      <Card>
                        <CardHeader title={translate('admin.comments')} />
                        <ListMaterial dense={true}>
                          <ListItem>
                            <FormGroup row style={{ width: '100%' }}>
                              <MuiAutocomplete
                                fullWidth
                                renderInput={(params) => (
                                  <MuiTextField
                                    {...params}
                                    margin="dense"
                                    onChange={(event) => {
                                      setComment(event.target.value);
                                    }}
                                    variant="filled"
                                    label={translate('admin.addComment')}
                                    helperText=" "
                                  />
                                )}
                                value={{value: comment, label: comment}}
                                onChange={(event, option: any) => {
                                  setComment(option?.value || '');
                                }}
                                options={(
                                  suggestionsData?.vehicleCommentSuggestions ||
                                  []
                                ).map((comment) => ({
                                  label: comment,
                                  value: comment,
                                }))}
                              />
                              <Button
                                style={{ margin: '0 auto' }}
                                disabled={!comment}
                                onClick={() => handleSave(false)}
                                variant="contained">
                                {translate('admin.addNewComment')}
                              </Button>
                              <Button
                                style={{ margin: '0 auto' }}
                                disabled={record.isReleased || !comment}
                                onClick={() => handleSave(true)}
                                variant="contained">
                                {translate('admin.saveAndReleaseIt')}
                              </Button>
                            </FormGroup>
                          </ListItem>
                          {data?.vehicleDamageComments.map((comment) => (
                            <ListItem key={comment.id}>
                              <ListItemText
                                primary={`${dayjs(comment.createdAt).format(
                                  'DD.MM.YYYY HH:mm',
                                )} (${comment?.user?.email})`}
                                secondary={comment.content}
                              />
                            </ListItem>
                          ))}
                        </ListMaterial>
                      </Card>
                    </>
                  );
                }}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export const VehicleDamageFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput label={translate('admin.search')} source="q" alwaysOn />
      <ReferenceInput
        source="bookingId"
        reference="Booking"
        label={translate('admin.bookingColumn')}>
        <AutocompleteInput
          optionText={(choice: any) => choice?.bookingHash}
          label={translate('admin.bookingColumn')}
        />
      </ReferenceInput>
      <SelectInput
        source="type"
        label={translate('admin.type')}
        choices={convertEnumFilterItems(VehicleDamageType)}
      />
      <SelectInput
        source="time"
        label={translate('admin.time')}
        choices={convertEnumFilterItems(VehicleDamageTime)}
      />
      <SelectInput
        source="kind"
        label={translate('admin.kind')}
        choices={convertEnumFilterItems(VehicleDamageKind)}
      />
      <ReferenceInput
        source="userId"
        reference="User"
        label={translate('admin.user')}>
        <AutocompleteInput
          optionText={(choice: any) => choice?.email}
          label={translate('admin.user')}
        />
      </ReferenceInput>
      <ReferenceInput
        source="vehicleId"
        reference="Vehicle"
        label={translate('admin.vehicle')}>
        <AutocompleteInput
          optionText={(choice: any) => choice?.vin}
          label={translate('admin.vehicle')}
        />
      </ReferenceInput>
    </Filter>
  );
};

const customExporter = (items) => {
  const listForExport = items.map((item) => ({
    vehicle: item.vehicle?.vin,
    booking: item.booking?.bookingHash,
    reportedBy: item.user?.email,
    type: item.type,
    time: item.time,
    kind: item.kind,
    createdAt: dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
  }));

  jsonExport(listForExport, {}, (err, csv) => {
    downloadCSV(csv, 'vehicleDamages');
  });
};

const ListActions = (props) => {
  return (
    <TopToolbar>
      {cloneElement(props.filters, { context: 'button' })}
      <CreateButton />
      <ExportButton maxResults={10000000} />
    </TopToolbar>
  );
};

export const VehicleDamageList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={customExporter}
      filters={<VehicleDamageFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="vehicle.vin" label={translate('admin.vehicle')} />
        <TextField
          source="booking.bookingHash"
          label={translate('admin.bookingHash')}
        />
        <TextField source="user.email" label={translate('admin.user')} />
        <TextField source="type" label={translate('admin.type')} />
        <TextField source="time" label={translate('admin.time')} />
        <TextField source="kind" label={translate('admin.kind')} />
        <DateField
          source="createdAt"
          showTime
          label={translate('admin.createdAt')}
        />
        <BooleanField source="isPublic" label={translate('admin.isPublic')} />
        <BooleanField
          source="isResolved"
          label={translate('admin.isResolved')}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment VehicleDamageFragment on VehicleDamage {
    vehicle {
      id
      vin
    }
    booking {
      id
      bookingHash
    }
    user {
      id
      email
    }
  }
`;

export const VehicleDamage: ResourceView = {
  resource: 'VehicleDamage',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
