import { boxHeight, zoom2ColumnWidth } from '../../constants';

import { Day } from '../../types/global';
import { Theme } from '../../styles';
import dayjs from 'dayjs';
import { drawCell } from './drawCell';
import { getIsBusinessDay } from '../dates';

export const drawHourlyView = (
  ctx: CanvasRenderingContext2D,
  rows: number,
  cols: number,
  startDate: Day,
  theme: Theme,
) => {
  const date = dayjs(
    `${startDate.year}-${startDate.month + 1}-${startDate.dayOfMonth + 1}`,
  );
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j <= cols; j++) {
      let hour;
      const convertedHour = j + startDate.hour;

      if (convertedHour === startDate.hour) {
        hour = dayjs();
      } else if (convertedHour > startDate.hour) {
        // next hours
        hour = dayjs().add(convertedHour, 'hours');
      } else {
        // previous hours
        hour = dayjs().subtract(convertedHour, 'hours');
      }
      const isCurrentHour =
        date.utc().isSame(dayjs(), 'day') && convertedHour === dayjs().get('hour');

      drawCell(
        ctx,
        j * zoom2ColumnWidth + zoom2ColumnWidth / 2 - 0.5, // -0.5 to make borders better aligned with hour axis
        i * boxHeight,
        zoom2ColumnWidth,
        getIsBusinessDay(hour),
        isCurrentHour,
        theme,
      );
    }
  }
};
