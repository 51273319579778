import * as React from 'react';

import { Button, ButtonProps } from 'react-admin';
import {
  Exporter,
  fetchRelatedRecords,
  useDataProvider,
  useListContext,
  useNotify,
} from 'ra-core';

import { CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { useCallback } from 'react';
import { useGetTransactionCalculationsLazyQuery } from '../../../@generated/hooks';

export const ExportButton = (props: ExportButtonProps) => {
  const {
    maxResults = 1000,
    onClick,
    label = 'ra.action.export',
    icon = defaultIcon,
    exporter: customExporter,
    meta,
    ...rest
  } = props;
  const {
    filter,
    filterValues,
    resource,
    sort,
    exporter: exporterFromContext,
    total,
  } = useListContext();
  const [getTransactionCalculations] = useGetTransactionCalculationsLazyQuery();

  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);
  const handleClick = useCallback(
    (event) => {
      setLoading(true);
      dataProvider
        .getList(resource, {
          sort,
          filter: filter
            ? { ...filterValues, ...filter, getCalculation: true }
            : { ...filterValues, getCalculation: true },
          pagination: { page: 1, perPage: maxResults },
          meta,
        })
        .then(async ({ data }) => {
          const _data = [];

          if (exporter) {
            const calculations = await getTransactionCalculations({
              variables: {
                transactionIds: data.map((item) => item.id),
              },
            });
            for (const calculation of calculations.data
              .getTransactionCalculations) {
              const foundTransaction = data.find(
                (item) => item.id === calculation.transactionId,
              );

              if (foundTransaction) {
                foundTransaction.calculation = calculation;
                _data.push(foundTransaction);
              }
            }

            exporter(
              _data,
              fetchRelatedRecords(dataProvider),
              dataProvider,
              resource,
            );

            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          notify('ra.notification.http_error', { type: 'error' });
        });
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [
      dataProvider,
      exporter,
      filter,
      filterValues,
      maxResults,
      notify,
      onClick,
      resource,
      sort,
      meta,
    ],
  );

  return (
    <Button
      onClick={handleClick}
      label={label}
      disabled={total === 0}
      {...sanitizeRestProps(rest)}>
      {loading ? <CircularProgress style={{ width: 18, height: 18 }} /> : icon}
    </Button>
  );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
  resource,
  ...rest
}: Omit<ExportButtonProps, 'maxResults' | 'label' | 'exporter' | 'meta'>) =>
  rest;

interface Props {
  exporter?: Exporter;
  icon?: JSX.Element;
  label?: string;
  maxResults?: number;
  onClick?: (e: Event) => void;
  resource?: string;
  meta?: any;
}

export type ExportButtonProps = Props & ButtonProps;
