import { CheckForApplicationUpdate, Layout } from 'react-admin';

import CustomAppBar from './CustomAppBar';
import CustomMenu from './CustomMenu';

const CustomLayout = (props) => {
  return <Layout {...props} appBar={CustomAppBar} menu={CustomMenu}>
    {props.children}
    <CheckForApplicationUpdate />
  </Layout>;
};

export default CustomLayout;
