import {
  leftColumnWidth,
  outsideWrapperId,
  screenWidthMultiplier,
  weekWidth,
  zoom2ColumnWidth
} from "../constants";

import dayjs from "dayjs";

export const getCols = (zoom: number, startDate: dayjs.Dayjs | Date) => {
  const wrapperWidth = document.getElementById(outsideWrapperId)?.clientWidth || 0;
  const componentWidth = wrapperWidth - leftColumnWidth;

  switch (zoom) {
    // weekly
    case 1:
      return dayjs(startDate).daysInMonth() + 2;
    // daily
    case 2:
      return Math.ceil(componentWidth / zoom2ColumnWidth) * screenWidthMultiplier;
    // monthly
    default:
      return Math.ceil(componentWidth / weekWidth) * screenWidthMultiplier;
  }
};

export const getVisibleCols = (zoom: number, startDate: dayjs.Dayjs | Date) => getCols(zoom, startDate) / screenWidthMultiplier;
