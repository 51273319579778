import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { UserAside, UserForm } from './form';
import { useCallback, useRef, useState } from 'react';
import {
  useResetPasswordMutation,
  useSaveOneTimePasswordMutation,
  useUploadVerificationDocumentMutation,
} from '../../@generated/hooks';

import { BookingQuestions } from './bookingQuestions';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { UserTabs } from './tabs';
import { User as UserType } from '../../@generated/schemas';
import sanitizeEmptyValues from '../../libs/sanitizeEmptyValues';
import styled from 'styled-components';
import { useErrors } from '../../libs/useErrors';
import { useTranslate } from '../../locales';

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 12px;
`;

const getTransformedData = (data) => {
  data.sharedVehicleGroupRelUser =
    data[
      '@@ra-many-to-many/User/SharedVehicleGroupRelUser/SharedVehicleGroup'
    ] || [];

  data.failedLoginAttempts = data.failedLoginAttempts || 0;
  data.phoneConfirmedAt = data.phoneConfirmedAt || false;

  return data;
};

const EditTitle = () => {
  const record = useRecordContext<UserType>();
  return <div>User: {record?.email}</div>;
};

const UserToolbar = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const uploadInputRef = useRef<any>();

  const [resetPassword, resetPasswordData] = useResetPasswordMutation();
  const [saveOneTimePassword, savePasswordData] =
    useSaveOneTimePasswordMutation();
  const [open, setOpen] = useState(false);
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [upload, { loading }] = useUploadVerificationDocumentMutation();

  const handleResetPassword = async () => {
    await resetPassword({ variables: { userId: record.id as string } });

    notify(translate('admin.successfullySent'), { type: 'success' });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = async () => {
    await saveOneTimePassword({
      variables: {
        userId: record.id as string,
        oneTimePassword: oneTimePassword,
      },
    });

    handleClose();
    setOneTimePassword('');

    notify(translate('admin.successfullySaved'), { type: 'success' });
  };

  const handleUploadVerificationDocument = async (event) => {
    const files = event.target.files;

    try {
      await upload({
        variables: {
          file: files[0],
          userId: record.id as string,
        },
      });

      refresh();

      uploadInputRef.current.value = null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Toolbar>
      <SaveButton />
      <Button
        onClick={handleResetPassword}
        style={{ marginLeft: 8 }}
        variant="outlined">
        {resetPasswordData.loading && (
          <CircularProgress style={{ height: 20, width: 20, marginRight: 4 }} />
        )}
        {translate('admin.resetPassword')}
      </Button>
      <Button
        onClick={() => setOpen(true)}
        style={{ marginLeft: 8 }}
        variant="outlined">
        {savePasswordData.loading && (
          <CircularProgress style={{ height: 20, width: 20, marginRight: 4 }} />
        )}
        {translate('admin.oneTimePassword')}
      </Button>

      <label htmlFor="upload-file">
        <input
          ref={uploadInputRef}
          id="upload-file"
          accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
          onChange={handleUploadVerificationDocument}
          type="file"
          hidden
        />
        <Button
          disabled={loading}
          variant="outlined"
          style={{ marginLeft: 8 }}
          onClick={() => uploadInputRef?.current?.click()}>
          {translate('admin.uploadVerificationDocuments')}
          {loading ? (
            <CircularProgress
              size="1rem"
              color="inherit"
              style={{ marginLeft: 8 }}
            />
          ) : (
            <CloudUploadIcon style={{ marginLeft: 8 }} />
          )}
        </Button>
      </label>

      <Modal open={open} onClose={handleClose}>
        <ModalContent>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: 24 }}>
            {translate('admin.setupOneTimePassword')}
          </Typography>

          <TextField
            value={oneTimePassword}
            style={{ width: '100%' }}
            rows={4}
            onChange={(event) => setOneTimePassword(event.target.value)}
            label={translate('admin.oneTimePassword')}
          />

          <Button
            onClick={handleClose}
            style={{ float: 'left', marginTop: 12 }}>
            {translate('admin.close')}
          </Button>

          <Button
            variant="contained"
            disabled={!oneTimePassword}
            onClick={handleSave}
            style={{ float: 'right', marginTop: 12 }}>
            {translate('admin.send')}
          </Button>
        </ModalContent>
      </Modal>
    </Toolbar>
  );
};

export const UserEdit = (props: any) => {
  const { errors, handleSetErrors } = useErrors();
  const notify = useNotify();
  const [saveEdit] = useUpdate();
  const translate = useTranslate();
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const onSubmit = useCallback(async (values) => {
    handleSetErrors({});
    try {
      const previousData = await dataProvider.getOne('User', {
        id: values.id,
      });
      await saveEdit(
        'User',
        {
          id: values.id,
          data: sanitizeEmptyValues(values),
          previousData: sanitizeEmptyValues(previousData.data),
        },
        {
          returnPromise: true,
        },
      );
      notify(translate('admin.successfullySaved'), { type: 'success' });
    } catch (error) {
      notify(error.message, { type: 'error' });
      return handleSetErrors(error);
    }
  }, []);

  return (
    <Edit
      {...props}
      transform={getTransformedData}
      mutationMode="pessimistic"
      title={<EditTitle />}
      aside={<UserAside />}>
      <SimpleForm onSubmit={onSubmit} toolbar={<UserToolbar />}>
        <UserTabs {...props}>
          <UserForm {...props} errors={errors} />
          <BookingQuestions {...props} />
        </UserTabs>
      </SimpleForm>
    </Edit>
  );
};
