const getStoragePath = (
  relativePath: string,
  bucket = 'drivemycar-gallery',
) => {
  if (relativePath.includes('gallery')) {
    return `https://ik.imagekit.io/jles49dvg/${relativePath.replace(
      'gallery/',
      '',
    )}`;
  }
  return `https://storage.googleapis.com/${bucket}/${relativePath}`;
};

export default getStoragePath;
