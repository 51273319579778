import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  TextField as MuiTextField,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useCreate,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
  useUpdate,
} from 'react-admin';
import {
  Booking,
  BookingQuestionKind,
  BookingQuestionType,
  BookingStatus,
  SendMessageEnum,
} from '../../@generated/schemas';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import {
  useGetBookingCalculationQuery,
  useGetBookingCheckAvailabilityLazyQuery,
  useGetBookingDetailQuery,
  useGetCancellationFeesLazyQuery,
  useRevokeBookingMutation,
  useSendMessageMutation,
  useVehicleTypesQueryQuery,
} from '../../@generated/hooks';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import SmsIcon from '@mui/icons-material/Sms';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import cookie from 'js-cookie';
import dayjs from 'dayjs';
import enLocale from 'date-fns/locale/en-GB';
import styled from 'styled-components';
import { url } from '../../';
import { useTranslate } from '../../locales';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BookingTitle = () => {
  const record = useRecordContext();
  return <span>Booking: {record?.bookingHash}</span>;
};

const DownloadDriverLicense = (props) => {
  const record = useRecordContext();
  const { source } = props;
  return (
    (record && (
      <a
        target="_blank"
        href={`${url}/admin/driverlicense/${
          record[source]
        }?access_token=${cookie.get('access_token')}`}
        download>
        Download
      </a>
    )) ||
    null
  );
};

const BookingOverviewPriceRow: React.VFC<{
  amount?: number;
  bold?: boolean;
  title: string;
  price?: number;
}> = ({ amount, price, title, bold }) => {
  const style: React.CSSProperties = bold ? { fontWeight: 'bold' } : {};
  return (
    <Row style={{ marginTop: 4 }}>
      <Typography fontSize={12} style={{ ...style, flex: 1 }}>
        {amount}
      </Typography>
      <Typography fontSize={12} style={{ ...style, flex: 4 }}>
        {title}
      </Typography>
      <Typography
        fontSize={12}
        style={{ ...style, flex: 2, textAlign: 'right' }}>
        {price !== undefined
          ? `CHF ${parseFloat(Number(price).toFixed(2))}`
          : ''}
      </Typography>
    </Row>
  );
};

const DEFAULT_PRICE = {
  totalCost: 0,
  costForDuration: 0,
  costForDistance: 0,
  includedDistance_km: 0,
  insuranceCost: 0,
  deductibleReduction: 0,
  totalCostWithoutInsurance: 0,
  totalHourlyCost: 0,
  lateFees: 0,
  totalDailyCost: 0,
  totalWeeklyCost: 0,
  totalHours: 0,
  totalDays: 0,
  totalWeeks: 0,
};

export const BookingPriceOverview = (props) => {
  const recordContext = useRecordContext();
  const record = recordContext || props;
  const translate = useTranslate();

  const price = props?.bookingPrice || {
    startPrice: DEFAULT_PRICE,
    endPrice: DEFAULT_PRICE,
  };

  const excessCost = price.endPrice?.costForDistance || 0;
  const originalBookingCost = record.totalCost;

  const additionalCost = price?.endPrice?.lateFees || 0;
  // parseFloat(
  //   formattedPrice(
  //     (price?.endPrice?.totalCostWithoutInsurance || 0) -
  //       (record?.totalCost ||
  //         price?.startPrice?.totalCostWithoutInsurance ||
  //         0),
  //     false,
  //   ),
  // ) - excessCost;

  const finalPrice =
    price?.endPrice?.totalCostWithoutInsurance ||
    price?.startPrice?.totalCostWithoutInsurance ||
    0;

  const totalHours =
    (price.endPrice
      ? price.endPrice?.totalHours
      : price.startPrice.totalHours) || 0;
  const totalHourlyCost =
    (price.endPrice
      ? price.endPrice?.totalHourlyCost
      : price.startPrice.totalHourlyCost) || 0;

  const totalDays =
    (price.endPrice ? price.endPrice?.totalDays : price.startPrice.totalDays) ||
    0;
  const totalDailyCost =
    (price.endPrice
      ? price.endPrice?.totalDailyCost
      : price.startPrice.totalDailyCost) || 0;

  const totalWeeks =
    (price.endPrice
      ? price.endPrice?.totalWeeks
      : price.startPrice.totalWeeks) || 0;
  const totalWeeklyCost =
    (price.endPrice
      ? price.endPrice?.totalWeeklyCost
      : price.startPrice.totalWeeklyCost) || 0;

  return (
    <div>
      {props?.loading && (
        <div
          style={{
            height: 150,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgress />
        </div>
      )}

      {!props?.loading && (
        <div>
          {totalHours > 0 && (
            <BookingOverviewPriceRow
              amount={totalHours || 0}
              title={`${translate('admin.hourlyRate')}, CHF ${
                record.priceHour || props.priceHour || 0
              }`}
              price={totalHourlyCost}
            />
          )}
          {(totalDays || 0) > 0 && (
            <BookingOverviewPriceRow
              amount={totalDays || 0}
              title={`${translate('admin.dailyRate')}, CHF ${
                record.priceDay || props.priceDay || 0
              }`}
              price={totalDailyCost}
            />
          )}
          {totalWeeks > 0 && (
            <BookingOverviewPriceRow
              amount={totalWeeks}
              title={`${translate('admin.weeklyRate')}, CHF ${
                record.priceWeek || props.priceWeek || 0
              }`}
              price={totalWeeklyCost}
            />
          )}
          <BookingOverviewPriceRow
            amount={
              price.endPrice?.includedDistance_km ||
              price.startPrice?.includedDistance_km ||
              0
            }
            title={`${translate('admin.includedKm')}*`}
          />
          {(price.endPrice?.costForDistance || 0) > 0 && (
            <BookingOverviewPriceRow
              amount={
                price.endPrice?.excessKm ||
                price.startPrice?.excessKm ||
                undefined
              }
              title={translate('admin.excessKm')}
              price={price.endPrice?.costForDistance || 0}
            />
          )}

          {(props.excessCost > 0 || additionalCost > 0) && (
            <>
              <BookingOverviewPriceRow
                title={translate('admin.additionalRentalCosts')}
              />
              {props?.excessCost > 0 && (
                <BookingOverviewPriceRow
                  amount={props?.excessKm || price.startPrice?.excessKm || 0}
                  title={`${translate('admin.excessMileage')}, CHF ${
                    price.startPrice?.excessKm
                  }/km`}
                  price={props?.excessCost}
                />
              )}
              {additionalCost > 0 && (
                <BookingOverviewPriceRow
                  title={translate('admin.lateFees')}
                  price={additionalCost}
                />
              )}
            </>
          )}

          <BookingOverviewPriceRow
            title={translate('admin.subtotalRentalCosts')}
            price={
              price?.endPrice?.totalCostWithoutInsurance ||
              price?.startPrice?.totalCostWithoutInsurance ||
              0
            }
            bold
          />

          <BookingOverviewPriceRow title={translate('admin.insuranceCosts')} />
          <BookingOverviewPriceRow
            title={translate('admin.insuranceCosts')}
            price={price.startPrice?.insuranceCost || 0}
          />
          <BookingOverviewPriceRow
            title={`${translate('admin.subtotalInsuranceCosts')}:`}
            price={price.startPrice?.insuranceCost || 0}
            bold
          />
          <BookingOverviewPriceRow
            title={translate('admin.totalCost')}
            price={
              price.endPrice?.totalCost > price.startPrice?.totalCost
                ? price.endPrice?.totalCost
                : price.startPrice?.totalCost
            }
            bold
          />
          <BookingOverviewPriceRow title={translate('admin.paymentSummary')} />

          {props.totalCost !==
            (price?.endPrice?.totalCost || price?.startPrice?.totalCost) && (
            <BookingOverviewPriceRow
              title={translate('admin.originalBookingTotalCost')}
              price={props.totalCost || 0}
            />
          )}

          <BookingOverviewPriceRow
            title={translate('admin.paidAtBookingStart')}
            price={price?.startPrice?.totalCost || 0}
          />
          <BookingOverviewPriceRow
            title={translate('admin.balanceAtBookingEnd')}
            price={
              (price?.endPrice?.totalCost || 0) > props?.totalCost
                ? (price?.endPrice?.totalCost || 0) -
                  (price?.startPrice?.totalCost || 0)
                : 0
            }
          />
          {(price.endPrice?.totalCost || price.startPrice?.totalCost) -
            originalBookingCost >
            0 &&
            [BookingStatus.Running, BookingStatus.Late].includes(
              props.bookingStatus,
            ) && (
              <BookingOverviewPriceRow
                title={translate('admin.balanceToBePaid')}
                price={
                  (price.endPrice?.totalCost || price.startPrice?.totalCost) -
                  originalBookingCost
                }
              />
            )}
          {(price.endPrice?.totalCost || price.startPrice?.totalCost) -
            originalBookingCost <
            0 &&
            [BookingStatus.Running, BookingStatus.Late].includes(
              props.bookingStatus,
            ) && (
              <BookingOverviewPriceRow
                title={translate('admin.balanceToBeRefunded')}
                price={Math.abs(
                  (price.endPrice?.totalCost || price.startPrice?.totalCost) -
                    originalBookingCost,
                )}
              />
            )}

          {price.startPrice?.refunding?.map((item) => (
            <BookingOverviewPriceRow
              key={item.createdAt}
              title={`${translate('admin.refundedAt')} ${dayjs(
                item.createdAt,
              ).format('DD.MM.YYYY HH:mm')}`}
              price={item.refunding}
            />
          ))}
          <BookingOverviewPriceRow
            key="effectiveBookingCost"
            title={translate('admin.effectiveBookingCost')}
            price={record.effectiveBookingCost}
          />

          <Typography fontSize={12} style={{ marginTop: 12 }}>
            {translate('admin.included')} CHF{' '}
            {Number(
              ((price.endPrice?.totalCostWithoutInsurance ||
                price.startPrice?.totalCostWithoutInsurance ||
                0) /
                Number(100 + props.vat)) *
                props.vat,
            ).toFixed(2)}{' '}
            ({props.vat}% VAT {translate('admin.vatFrom')} CHF{' '}
            {Number(finalPrice).toFixed(2)})
          </Typography>
          <Typography fontSize={12} style={{ marginTop: 12 }}>
            {translate('admin.included')}. Fr.{' '}
            {Number((price.startPrice?.insuranceCost || 0) * 0.05).toFixed(2)}{' '}
            (5% Stempelsteuer from CHF{' '}
            {Number(price.startPrice?.insuranceCost || 0).toFixed(2)})
          </Typography>
          <Typography fontSize={12} style={{ marginTop: 12 }}>
            {translate('admin.excessKmWillBeChargedWith')}{' '}
            {record.priceKm || props.priceKm || 0}/km
          </Typography>
        </div>
      )}
    </div>
  );
};

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 24px;
`;

const RevokeButton = (props) => {
  const { record } = useShowContext();
  const refreshData = useRefresh();
  const [open, setOpen] = useState(false);
  const [refunding, setRefunding] = useState<number | undefined>(undefined);
  const [withInsurance, setWithInsurance] = useState(false);
  const [revokeReason, setRevokeReason] = useState<string>('');
  const onClose = () => setOpen(false);
  const { data } = useVehicleTypesQueryQuery({ fetchPolicy: 'no-cache' });
  const [revoke] = useRevokeBookingMutation();
  const translate = useTranslate();

  const handleSave = async () => {
    await revoke({
      variables: {
        bookingId: record.id,
        revokeReason,
        refunding,
        withInsurance,
      },
    });
    setRevokeReason('');
    setWithInsurance(false);
    setRefunding(undefined);
    props?.refetchBookingCalculation?.();
    onClose();
    refreshData();
  };

  const refundAmount =
    Math.round(
      withInsurance
        ? props?.startPrice?.totalCost
        : props?.startPrice?.totalCostWithoutInsurance,
    ) *
    (refunding / 100);

  return (
    <div>
      <Button
        disabled={record.revokedAt}
        variant="contained"
        color="error"
        style={{ marginRight: 16 }}
        onClick={() => setOpen(true)}>
        {translate('admin.revokeBooking')}
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContent>
          <IconButton
            style={{ position: 'absolute', right: 18, top: 10 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            {translate('admin.revokeReason')}
          </Typography>

          <Select
            style={{ width: '100%' }}
            value={revokeReason}
            variant="outlined"
            size="small"
            onChange={(event) => setRevokeReason(event.target.value)}>
            {(data?.revokeReasonTypes || []).map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>

          {(record.stripePaymentConfirmedAt ||
            record.stripeAdditionalPaymentConfirmed) && (
            <FormControlLabel
              style={{
                marginTop: 18,
              }}
              control={
                <Checkbox
                  checked={refunding !== undefined}
                  onChange={() =>
                    setRefunding(refunding === undefined ? 0 : undefined)
                  }
                />
              }
              label={translate('admin.refunding')}
            />
          )}

          {refunding !== undefined && (
            <FormGroup
              style={{
                marginTop: 18,
                display: 'flex',
                flexDirection: 'row',
              }}>
              <FormControlLabel
                style={{ flex: 1 }}
                control={
                  <Checkbox
                    checked={withInsurance}
                    onChange={() => setWithInsurance(!withInsurance)}
                  />
                }
                label="With insurance"
              />
              <OutlinedInput
                style={{ flex: 1 }}
                value={refunding}
                onChange={(event) =>
                  Number(event?.target?.value) >= 0 &&
                  Number(event?.target?.value) <= 100 &&
                  setRefunding(Number(event?.target?.value))
                }
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                size="small"
                type="number"
              />
            </FormGroup>
          )}

          {refunding !== undefined && (
            <List dense={true}>
              <ListItem>
                <ListItemButton>
                  <ListItemText
                    primary={`${translate('admin.totalCost')}: CHF ${
                      props?.startPrice?.totalCost
                    }`}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemText
                    style={{ fontWeight: 'bold' }}
                    primary={
                      <b>{`${translate('admin.refundedCost')}: CHF ${Number(
                        withInsurance
                          ? refundAmount > props?.startPrice?.totalCost
                            ? props?.startPrice?.totalCost
                            : refundAmount
                          : refundAmount >
                            props?.startPrice?.totalCostWithoutInsurance
                          ? props?.startPrice?.totalCostWithoutInsurance
                          : refundAmount,
                      ).toFixed(2)}`}</b>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )}

          <Button
            variant="contained"
            onClick={handleSave}
            style={{ float: 'right', marginTop: 20 }}>
            {translate('admin.saveAndRevoke')}
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
};

const ChangeEffectiveBookingCostButton = () => {
  const { record } = useShowContext();
  const { identity } = useGetIdentity();
  const refreshData = useRefresh();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [refundReason, setRefundReason] = useState<string>('');
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const onClose = () => setOpen(false);
  const [updateBooking] = useUpdate('Booking');
  const [createBookingChangeHistory] = useCreate('BookingChangeHistory');

  const handleSave = async () => {
    await updateBooking('Booking', {
      id: record.id as string,
      data: {
        effectiveBookingCost: Number(refundAmount),
      },
    });
    await createBookingChangeHistory('BookingChangeHistory', {
      data: {
        originalTotalCost: Number(record.effectiveBookingCost),
        originalStartDate: null,
        originalEndDate: null,
        reason: refundReason,
        booking: record.id,
        newTotalCost: Number(refundAmount),
        newStartDate: null,
        newEndDate: null,
        createdByUser: identity.id,
      },
    });
    setRefundReason('');
    setRefundAmount(0);
    onClose();
    refreshData();
  };

  return (
    <div>
      <Button
        disabled={record.revokedAt}
        variant="contained"
        color="info"
        style={{ marginRight: 16 }}
        onClick={() => setOpen(true)}>
        {translate('admin.changeEffectiveBookingCost')}
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContent>
          <IconButton
            style={{ position: 'absolute', right: 18, top: 10 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            {translate('admin.changeReason')}
          </Typography>

          <FormControlLabel
            style={{
              marginTop: 18,
              width: '100%',
            }}
            control={
              <Input
                type="number"
                fullWidth
                onChange={(event: any) => setRefundAmount(event.target.value)}
                placeholder={translate('admin.bookingEffectiveCost')}
              />
            }
            label=""
          />

          <FormControlLabel
            style={{
              marginTop: 18,
              width: '100%',
            }}
            control={
              <Input
                fullWidth
                onChange={(event: any) => setRefundReason(event.target.value)}
                multiline
                minRows={3}
                placeholder={translate('admin.reason')}
              />
            }
            label=""
          />

          <Button
            variant="contained"
            onClick={handleSave}
            style={{ float: 'right', marginTop: 20 }}>
            {translate('admin.save')}
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
};

const BookingVehicle = (props: any) => {
  const translate = useTranslate();
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>{translate('admin.vin')}</Typography>
          <FunctionField
            label={translate('admin.vin')}
            render={(row) => {
              return (
                <Link to={`/Vehicle/${row?.vehicleId}`}>{props?.vin}</Link>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.licensePlate')}
            render={(row) => {
              return (
                <Link to={`/Vehicle/${row?.vehicleId}`}>
                  {props?.plateNumber}
                </Link>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.brand')}
            render={() => {
              return `
                ${props?.brandType?.name}
                (${props?.name || ''})
              `;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.model')}
            render={() => {
              return `${props?.modelType?.name}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const BookingDetail = (props: any) => {
  const translate = useTranslate();
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>{translate('admin.bookingColumn')}</Typography>
          <TextField
            source="bookingHash"
            label={translate('admin.bookingHash')}
          />
          <DateField
            source="startDate"
            label={translate('admin.bookingStart')}
            showTime
            locales="de"
          />
          <DateField
            source="effectiveStartDate"
            label={translate('admin.effectiveStart')}
            showTime
            locales="de"
          />
          <DateField
            source="createdAt"
            label={translate('admin.createdAt')}
            showTime
            locales="de"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.pickupLocation')}
            render={() => {
              return `${props?.vehicleLocation?.name || ''}${
                props.pickupLocationNotes
                  ? ` (${props.pickupLocationNotes})`
                  : ''
              }`;
            }}
          />
          <DateField
            source="endDate"
            locales="de-DE"
            label={translate('admin.endDate')}
            showTime
          />
          <DateField
            source="effectiveEndDate"
            label={translate('admin.effectiveEnd')}
            showTime
            locales="de"
          />
          {props.cancelDate && (
            <DateField
              source="cancelDate"
              label={translate('admin.cancelledAt')}
              showTime
              locales="de"
            />
          )}
          {props.revokedAt && (
            <DateField
              source="revokedAt"
              label={translate('admin.revokedAt')}
              showTime
              locales="de"
            />
          )}
          <TextField
            label={translate('admin.bookingStatus')}
            source="bookingStatus"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.returnLocationNotes')}
            render={() => {
              return `${
                props.returnLocationNotes
                  ? `(${props.returnLocationNotes})`
                  : ''
              }`;
            }}
          />
          <FunctionField
            source="vehicleMileageOnEffectiveStart"
            label={translate('admin.drivenKm')}
            render={(row) =>
              `${
                row.vehicleMileageOnEffectiveEnd
                  ? Math.abs(
                      row.vehicleMileageOnEffectiveEnd -
                        row.vehicleMileageOnEffectiveStart,
                    )
                  : 0
              } km`
            }
          />
          <FunctionField
            source="vehicleMileageOnEffectiveStart"
            label={translate('admin.startEndMileage')}
            render={(row) =>
              `${row.vehicleMileageOnEffectiveStart || 0} km / ${
                row.vehicleMileageOnEffectiveEnd || 0
              } km`
            }
          />
          <FunctionField
            render={(record) => `${record.commission}%`}
            source="commission"
            label={translate('admin.commissionToOwner')}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            source="revokeReason"
            label={translate('admin.revokeComment')}
            render={(row) => row.revokeReason || '-'}
          />
          <FunctionField
            source="adminBookingComment"
            label={translate('admin.adminComment')}
            render={(row) => row.adminBookingComment || '-'}
          />
          <FunctionField
            source="adminChangeComment"
            label={translate('admin.adminDateChangeComment')}
            render={(row) => row.adminChangeComment || '-'}
          />
          <BooleanField
            source="skipVehicleStatus"
            label={translate('admin.skippedVehicleStatus')}
            defaultChecked={false}
          />
          {props.convadisBookingIncrement ? (
            <FunctionField
              source="convadisBookingIncrement"
              label={translate('admin.convadisReservation')}
              render={(row) => row.convadisBookingIncrement}
            />
          ) : null}
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const BookingUser = (props: any) => {
  const translate = useTranslate();
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>{translate('admin.user')}</Typography>
          <ReferenceField source="userId" reference="User">
            <FunctionField source="email" render={row => row.email || 'Link'} label={translate('admin.emailField')} />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.firstName')}
            render={() => {
              return `${props?.firstName}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.lastName')}
            render={() => {
              return `${props?.lastName}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.phoneNumber')}
            render={() => {
              return `${props?.phone}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const BookingInsurance = (props: any) => {
  const vehicleInsuranceType = props?.vehicleInsuranceType;
  const translate = useTranslate();

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={3}>
          <SimpleShowLayout>
            <Typography>{translate('admin.insurance')}</Typography>
            <FunctionField
              label={translate('admin.insuranceName')}
              render={() =>
                vehicleInsuranceType?.name && (
                  <Link
                    to={`/VehicleInsuranceType/${vehicleInsuranceType?.id}`}>
                    {vehicleInsuranceType?.name}
                  </Link>
                )
              }
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={3}>
          <SimpleShowLayout>
            <Typography>&nbsp;</Typography>
            <FunctionField
              label={translate('admin.insuranceCost')}
              render={() => {
                return `CHF ${Number(
                  (props?.startPrice?.totalCost || 0) -
                    props?.startPrice?.totalCostWithoutInsurance,
                ).toFixed(2)}`;
              }}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <div style={{ width: '100%' }}>
        <SimpleShowLayout>
          <ReferenceArrayField
            label={translate('admin.additionalDrivers')}
            source="bookingAdditionalDriver_ids"
            emptyText={translate('admin.additionalDrivers')}
            reference="BookingAdditionalDriver">
            <Datagrid style={{ width: '100%' }}>
              <TextField
                source="firstName"
                label={translate('admin.firstName')}
              />
              <TextField
                source="lastName"
                label={translate('admin.lastName')}
              />
              <DownloadDriverLicense
                source="photoFrontName"
                label={translate('admin.front')}
              />
              <DownloadDriverLicense
                source="photoBackName"
                label={translate('admin.back')}
              />
            </Datagrid>
          </ReferenceArrayField>
        </SimpleShowLayout>
      </div>
    </div>
  );
};

const BookingAnswers = (props: any) => {
  return props.data.map((bookingQuestion) => (
    <div key={bookingQuestion.id} style={{ marginBottom: 20 }}>
      <div style={{ minWidth: 100 }}>
        <b>
          {bookingQuestion?.bookingQuestion?.parsedTitle['en'] ||
            Object.keys(bookingQuestion?.bookingQuestion?.parsedTitle)[0]}
          :
        </b>
      </div>
      {bookingQuestion.bookingQuestion.kind === BookingQuestionKind.Boolean && (
        <div>
          {(bookingQuestion?.answer === 'true' && <CheckIcon />) || (
            <CloseIcon />
          )}
        </div>
      )}
      {bookingQuestion.bookingQuestion.kind === BookingQuestionKind.Rating && (
        <Row style={{ width: 200 }}>
          {Array(5)
            .fill('')
            .map((_, key) => {
              if (Number(bookingQuestion.answer) <= Number(key)) {
                return <StarBorderIcon />;
              }
              return <StarIcon />;
            })}
        </Row>
      )}
      {bookingQuestion.bookingQuestion.kind === BookingQuestionKind.String && (
        <div>{bookingQuestion?.answer}</div>
      )}
    </div>
  ));
};

const BookingDamages = (props: any) => {
  const translate = useTranslate();
  const { bookingAnswers, bookingId } = props;
  const preBookingQuestions = [];
  const postBookingQuestions = [];

  (bookingAnswers || []).forEach((bookingAnswer) => {
    if (bookingAnswer.bookingQuestion.type === BookingQuestionType.Pre) {
      preBookingQuestions.push(bookingAnswer);
    }

    if (bookingAnswer.bookingQuestion.type === BookingQuestionType.Post) {
      postBookingQuestions.push(bookingAnswer);
    }
  });

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>Damages</Typography>
            {props?.bookingDamages?.length === 0 &&
              translate('admin.noDamages')}
            {props?.bookingDamages?.length > 0 && (
              <FunctionField
                label={translate('admin.reportedDamages')}
                render={() =>
                  props?.bookingDamages?.length === 0 ? (
                    translate('admin.noDamages')
                  ) : (
                    <a
                      target="_blank"
                      href={`/#/VehicleDamage/?displayedFilters=%7B"bookingId"%3Atrue%7D&filter=%7B"bookingId"%3A"${bookingId}"%7D&order=DESC&page=1&perPage=10&sort=createdAt`}>
                      {translate('admin.redirectToList')}
                    </a>
                  )
                }
              />
            )}
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography style={{ marginBottom: 24 }}>
              {translate('admin.preBookingQuestions')}
            </Typography>
            <BookingAnswers data={preBookingQuestions} />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography style={{ marginBottom: 24 }}>
              {translate('admin.postBookingQuestions')}
            </Typography>
            <BookingAnswers data={postBookingQuestions} />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </div>
  );
};

const BookingCostSummary = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>{translate('admin.costSummary')}</Typography>
            <FunctionField
              label={translate('admin.price')}
              render={() => <BookingPriceOverview {...props} />}
            />
            <FunctionField
              source="sharedVehicleGroup"
              label={translate('admin.svg')}
              render={(row) =>
                row.sharedVehicleGroup ? (
                  <Link
                    to={`/SharedVehicleGroup/${row.sharedVehicleGroup?.id}`}>
                    {row.sharedVehicleGroup?.name} (
                    {translate('admin.discount')}:{' '}
                    {row.sharedVehicleGroup?.discount}%,{' '}
                    {translate('admin.ownerBehavior')}:{' '}
                    {row.sharedVehicleGroup?.ownerBehavior
                      ? translate('admin.yes')
                      : translate('admin.no')}
                    ,{translate('admin.private')}:{' '}
                    {row.sharedVehicleGroup?.isPrivate
                      ? translate('admin.yes')
                      : translate('admin.no')}
                    )
                  </Link>
                ) : (
                  '-'
                )
              }
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>&nbsp;</Typography>
            <FunctionField
              label={translate('admin.stripeAuthorizations')}
              render={() =>
                props?.stripePaymentConfirmedAt &&
                dayjs(props?.stripePaymentConfirmedAt).format(
                  'DD.MM. YYYY HH:mm',
                )
              }
            />

            <FunctionField
              label={translate('admin.changedHistory')}
              render={() =>
                props.bookingChangeHistory?.map((history) => (
                  <div key={history.id}>
                    <Row style={{ marginTop: 4 }}>
                      <Typography fontSize={12} style={{ flex: 4 }}>
                        {translate('admin.originalDate')}:{' '}
                        {dayjs(history.originalStartDate).format(
                          'DD.MM.YYYY HH:mm',
                        )}{' '}
                        -{' '}
                        {dayjs(history.originalEndDate).format(
                          'DD.MM.YYYY HH:mm',
                        )}{' '}
                        (CHF {history.originalTotalCost})
                        <br />
                        {translate('admin.new')}:{' '}
                        {dayjs(history.newStartDate).format('DD.MM.YYYY HH:mm')}{' '}
                        - {dayjs(history.newEndDate).format('DD.MM.YYYY HH:mm')}{' '}
                        (CHF {history.newTotalCost})
                        <br />
                        {history.reason}
                      </Typography>
                      <Typography
                        fontSize={12}
                        style={{ flex: 2, textAlign: 'right' }}>
                        {translate('admin.by')} {history.createdByUser.email}{' '}
                        {history.createdByUser.userRole
                          ? `(${history.createdByUser.userRole})`
                          : ''}
                        <br />
                        {translate('admin.at')}{' '}
                        {dayjs(history.createdAt).format('DD.MM.YYYY HH:mm')}
                      </Typography>
                    </Row>
                    <hr />
                  </div>
                ))
              }
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>&nbsp;</Typography>
            <ArrayField
              label={translate('admin.bookingFuelPasswordRequests')}
              source="bookingFuelPassword">
              <Datagrid style={{ width: '100%' }} bulkActionButtons={false}>
                <DateField
                  source="createdAt"
                  label={translate('admin.requestedAt')}
                  showTime
                />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </div>
  );
};

type SendMessageProps = {
  variant: SendMessageEnum;
  title: string;
  bookingId: string;
  icon: React.ReactElement;
};

const SendMessage = (props: SendMessageProps) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>('');
  const onClose = () => setOpen(false);
  const [sendMessage] = useSendMessageMutation();

  const handleSend = async () => {
    await sendMessage({
      variables: {
        bookingId: props?.bookingId,
        message,
        variant: props.variant,
      },
    });
    setMessage('');
    onClose();
    notify(translate('admin.messageWasSent'), { type: 'success' });
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{ marginRight: 16 }}
        startIcon={props.icon}
        onClick={() => setOpen(true)}>
        {props.title}
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContent>
          <IconButton
            style={{ position: 'absolute', right: 18, top: 10 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            {props.title}
          </Typography>

          <MuiTextField
            label={translate('admin.message')}
            multiline
            style={{ width: '100%' }}
            maxRows={4}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            variant="outlined"
            size="small"
          />

          <Button
            variant="contained"
            onClick={handleSend}
            style={{ float: 'right', marginTop: 20 }}>
            {translate('admin.send')}
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
};

type SkipVehicleStatusButtonProps = {
  title: string;
  bookingId: string;
  onRefresh: () => void;
};

const SkipVehicleStatusButton = (props: SkipVehicleStatusButtonProps) => {
  const notify = useNotify();
  const translate = useTranslate();
  const record = useRecordContext<Booking>();
  const [updateBooking] = useUpdate();

  const handleSkipVehicleStatus = async () => {
    await updateBooking('Booking', {
      id: record.id as string,
      data: {
        skipVehicleStatus: true,
      },
    });
    notify(translate('admin.messageWasUpdated'), { type: 'success' });
    props.onRefresh();
  };

  return (
    <Button
      variant="contained"
      style={{ marginRight: 16 }}
      onClick={handleSkipVehicleStatus}>
      {props.title}
    </Button>
  );
};

export const BookingShow = (props: any) => {
  return (
    <Show title={<BookingTitle />} className="booking-detail" {...props}>
      <BookingShowContent {...props} />
    </Show>
  );
};

const EditBookingDate = (props: { onRefresh: () => void }) => {
  const notify = useNotify();
  const record = useRecordContext<Booking>();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [adminChangeComment, setSdminChangeComment] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(record?.startDate);
  const [endDate, setEndDate] = useState<string>(record?.endDate);
  const onClose = () => {
    setError('');
    setStartDate(record?.startDate);
    setEndDate(record?.endDate);
    setOpen(false);
  };
  const [updateBooking] = useUpdate();
  const translate = useTranslate();
  const [getBookingCheckAvailabilityLazyQuery] =
    useGetBookingCheckAvailabilityLazyQuery();
  const [getCancellationFeesLazyQuery] = useGetCancellationFeesLazyQuery();

  const checkAvailability = async () => {
    try {
      const { data } = await getBookingCheckAvailabilityLazyQuery({
        variables: {
          userId: record.userId,
          bookingId: record.id as string,
          startDateTime: dayjs(startDate).toISOString(),
          endDateTime: dayjs(endDate).toISOString(),
        },
      });

      const { data: cancellationData } = await getCancellationFeesLazyQuery({
        variables: {
          bookingId: record.id as string,
        },
      });

      const originalDuration = dayjs(record.endDate).diff(
        dayjs(record.startDate),
        'minutes',
      );
      const newDuration = dayjs(endDate).diff(dayjs(startDate), 'minutes');

      // shorter booking within cancellation fees should not be possible
      if (
        cancellationData?.getCancellationFees?.cancellationFees > 0 &&
        originalDuration > newDuration
      ) {
        return setError(
          `${translate('admin.notAvailableCancellationFees')} ${
            cancellationData?.getCancellationFees?.cancellationFees
          }`,
        );
      }

      if (
        !(data?.getBookingCheckAvailability || []).includes(record.vehicleId)
      ) {
        return setError(translate('admin.notAvailable'));
      }

      return true;
    } catch (e) {
      setError(e.message);
      return false;
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      if (dayjs(endDate).diff(dayjs(startDate), 'hours', true) < 1) {
        setError(translate('admin.minReservation'));
      } else {
        setError('');
      }

      checkAvailability();
    }
  }, [startDate, endDate]);

  const handleSend = async () => {
    setError('');

    const isAvailable = await checkAvailability();

    if (!isAvailable) return false;

    await updateBooking('Booking', {
      id: record.id as string,
      data: {
        startDate,
        endDate,
        userConfirmedAt: null,
        adminChangeComment,
        adminChangeCommentAt: new Date(),
      },
    });
    onClose();
    notify(translate('admin.messageWasUpdated'), { type: 'success' });
    props.onRefresh();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Button
        variant="contained"
        disabled={
          [
            BookingStatus.Cancelled,
            BookingStatus.Aborted,
            BookingStatus.Finished,
            BookingStatus.Revoked,
            BookingStatus.Unknown,
          ].includes(record?.bookingStatus)
        }
        startIcon={<QueryBuilderIcon />}
        style={{ marginRight: 16 }}
        onClick={() => setOpen(true)}>
        {translate('admin.editDate')}
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContent>
          <IconButton
            style={{ position: 'absolute', right: 18, top: 10 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            {translate('admin.editBookingDate')}
          </Typography>
          <DateTimePicker
            disabled={
              ![BookingStatus.Pending, BookingStatus.Confirmed].includes(
                record?.bookingStatus,
              )
            }
            format="DD.MM.YYYY HH:mm"
            ampm={false}
            label={translate('admin.startDateTime')}
            value={dayjs(startDate)}
            onChange={value => setStartDate(value.toISOString())}
          />
          <DateTimePicker
            format="DD.MM.YYYY HH:mm"
            ampm={false}
            label={translate('admin.endDateTime')}
            value={dayjs(endDate)}
            onChange={value => setEndDate(value.toISOString())}
          />

          <MuiTextField
            label={translate('admin.adminComment')}
            multiline
            style={{ width: '100%' }}
            maxRows={4}
            value={adminChangeComment}
            onChange={(event) => setSdminChangeComment(event.target.value)}
            variant="outlined"
            size="small"
          />

          {error && (
            <Alert style={{ marginTop: 12 }} severity="error">
              {error}
            </Alert>
          )}

          <Button
            variant="contained"
            disabled={!adminChangeComment || !!error}
            onClick={handleSend}
            style={{ float: 'right', marginTop: 20 }}>
            {translate('admin.update')}
          </Button>
        </ModalContent>
      </Modal>
    </LocalizationProvider>
  );
};

export const BookingShowContent = () => {
  const record = useRecordContext<Booking>();
  const refresh = useRefresh();
  const translate = useTranslate();

  const { data, refetch: refetchBookingDetail } = useGetBookingDetailQuery({
    variables: {
      bookingId: record?.id as string,
    },
    skip: !record?.id,
  });
  const {
    data: bookingPriceData,
    loading,
    refetch: refetchBookingCalculation,
  } = useGetBookingCalculationQuery({
    variables: {
      bookingId: record?.id as string,
    },
    fetchPolicy: 'no-cache',
    skip: !record?.id,
  });

  const handleRefresh = async () => {
    refresh();
    await refetchBookingDetail();
    await refetchBookingCalculation();
  };

  const bookingDetail = data?.getBookingDetail;
  const bookingDamages = data?.getBookingDetail?.vehicleDamage || [];
  const vehicleDetail = bookingDetail?.vehicle;
  const bookingPrice = bookingPriceData?.getBookingCalculation;

  return (
    <div>
      <BookingDetail {...bookingDetail} {...record} />

      <hr />

      <BookingVehicle {...vehicleDetail} />

      <hr />

      <BookingUser {...bookingDetail?.user} />

      <hr />

      <BookingInsurance {...bookingDetail} {...bookingPrice} />

      <hr />

      <BookingDamages
        bookingAnswers={bookingDetail?.bookingAnswer}
        bookingHash={bookingDetail?.bookingHash}
        bookingId={bookingDetail?.id}
        bookingDamages={bookingDamages}
      />

      <hr />

      <BookingCostSummary
        {...bookingDetail}
        loading={loading}
        bookingPrice={bookingPrice}
      />

      <hr />

      <SimpleShowLayout>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => window.open('/#/VehicleDamage/create', '_blank')}
            style={{ marginRight: 16 }}>
            {translate('admin.reportDamage')}
          </Button>
          {!bookingDetail?.effectiveEndDate &&
            !record?.cancelDate &&
            !record?.revokedAt && (
              <RevokeButton
                {...bookingPrice}
                refetchBookingCalculation={refetchBookingCalculation}
              />
            )}
          <ChangeEffectiveBookingCostButton />
          <SendMessage
            title={translate('admin.sendPushNotification')}
            icon={<NotificationsNoneIcon />}
            bookingId={bookingDetail?.id}
            variant={SendMessageEnum.Notification}
          />
          <SendMessage
            title={translate('admin.sendSms')}
            icon={<SmsIcon />}
            bookingId={bookingDetail?.id}
            variant={SendMessageEnum.Sms}
          />
          <SkipVehicleStatusButton
            title={translate('admin.skipVehicleStatus')}
            onRefresh={handleRefresh}
            bookingId={bookingDetail?.id}
          />
          <Button
            variant="outlined"
            onClick={() =>
              window.open(
                `${url}/booking/report/${bookingDetail?.id}`,
                '_blank',
              )
            }
            style={{ marginRight: 16 }}>
            {translate('admin.downloadPdf')}
          </Button>
          <EditBookingDate onRefresh={handleRefresh} />
        </Row>
      </SimpleShowLayout>
    </div>
  );
};
