import { FormLabel, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';

import InsuranceContent from '../../insurance/content';
import InsuranceDetails from '../../../modules/insurance/details';
import InsuranceField from '../../../modules/insurance/insuranceField';
import InsuranceRuleSets from '../../insurance/ruleSets';
import { Vehicle } from '../../../@generated/schemas';
import styled from 'styled-components';
import { useRecordContext } from 'react-admin';
import { useState } from 'react';
import { useTranslate } from '../../../locales';

export const requiredFields = [
  'vehicleInsuranceType.name',
  'vehicleInsuranceType.policyNumber',
  'vehicleInsuranceType.policyHolder',
  'vehicleInsuranceType.postalAddress',
];

const INSURANCE_TABS = {
  details: 'details',
  rulesets: 'rulesets',
  content: 'content',
  links: 'links',
} as const;

export const VehicleInsurance: React.VFC = () => {
  const record = useRecordContext<Vehicle>();
  const translate = useTranslate();

  const [selectedTab, setTab] = useState<keyof typeof INSURANCE_TABS>(
    INSURANCE_TABS.details,
  );
  return (
    <TabContext value={selectedTab}>
      <StyledFormLabel>{translate('admin.insurance')}</StyledFormLabel>
      <TabList
        defaultValue={selectedTab}
        onChange={(_, value) => setTab(value)}
        aria-label="">
        <Tab label={translate('admin.details')} value={INSURANCE_TABS.details} />
        <Tab label={translate('admin.content') }value={INSURANCE_TABS.content} />
        <Tab label={translate('admin.links')} value={INSURANCE_TABS.links} />
        <Tab label={translate('admin.rulesets')} value={INSURANCE_TABS.rulesets} />
      </TabList>
      <br />
      {selectedTab === INSURANCE_TABS.content && (
        <FullWidth>
          <InsuranceContent source="vehicleInsuranceType.content" />
        </FullWidth>
      )}
      {selectedTab === INSURANCE_TABS.links && (
        <FullWidth>
          <InsuranceContent source="vehicleInsuranceType.contentLinks" />
        </FullWidth>
      )}
      {selectedTab === INSURANCE_TABS.details && (
        <FullWidth>
          <InsuranceDetails
            vehicleInsuranceTypeId={record?.vehicleInsuranceType?.id}>
            <InsuranceField
              source="vehicleInsuranceType.name"
              label={translate('admin.name')}
              requiredFields={requiredFields}
            />
            <InsuranceField
              source="vehicleInsuranceType.policyNumber"
              label={translate('admin.policyNumber')}
              requiredFields={requiredFields}
            />
            <InsuranceField
              source="vehicleInsuranceType.policyHolder"
              label={translate('admin.policyHolder')}
              requiredFields={requiredFields}
            />
            <InsuranceField
              source="vehicleInsuranceType.postalAddress"
              label={translate('admin.postalAddress')}
              requiredFields={requiredFields}
            />
            <InsuranceField
              source="vehicleInsuranceType.billingAddress"
              label={translate('admin.billingAddress')}
              requiredFields={requiredFields}
            />
            <InsuranceField
              source="vehicleInsuranceType.phoneNumber"
              label={translate('admin.phoneNumber')}
              requiredFields={requiredFields}
            />
            <InsuranceField
              source="vehicleInsuranceType.emailAddress"
              type="email"
              label={translate('admin.emailField')}
              requiredFields={requiredFields}
            />
          </InsuranceDetails>
        </FullWidth>
      )}
      {selectedTab === INSURANCE_TABS.rulesets && (
        <FullWidth>
          <InsuranceRuleSets
            vehicleInsuranceTypeId={record?.vehicleInsuranceTypeId}
            vehicleInsuranceRuleSets={
              record?.vehicleInsuranceType?.vehicleInsuranceRuleSets
            }
          />
        </FullWidth>
      )}
    </TabContext>
  );
};

const FullWidth = styled.div`
  width: 100%;
`;

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 22px;
`;
