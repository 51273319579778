import {
  Datagrid,
  EditButton,
  List,
  ListActions,
  TextField,
} from 'react-admin';

import CustomPagination from '../../../CustomPagination';
import { useTranslate } from '../../../locales';

export const GateControllersList = () => {
  const translate = useTranslate();
  return (
    <List title={translate('admin.gateControllers')} actions={<ListActions hasCreate />} pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="gateId" label={translate('admin.id')} />
        <TextField source="company.name" label={translate('admin.company')} />
        <TextField source="gateType.name" label={translate('admin.gateType')} />
        <EditButton />
      </Datagrid>
    </List>
  );
};
