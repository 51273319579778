import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  List,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetIdentity,
  useRecordContext,
  useRefresh,
} from 'react-admin';

import CustomPagination from '../../CustomPagination';
import { Grid } from '@mui/material';
import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';
import { UserRole } from '../../@generated/schemas';
import { renderLabel } from '../sharedVehicleGroup/form';
import { useTranslate } from '../../locales';

const Form = () => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  return (
    <Grid container spacing={8}>
      <Grid item md={5}>
        <TextInput source="name" label={translate('admin.name')} fullWidth />
        {identity?.userRole && identity?.userRole === UserRole.SuperAdmin && (
          <ReferenceInput
            source="company_id"
            reference="Company"
            filter={{
              deletedAt: { equals: null },
            }}>
            <AutocompleteInput
              label={translate('admin.company')}
              fullWidth
              optionText="name"
            />
          </ReferenceInput>
        )}

        <ReferenceManyToManyInput
          resource="CompanyVehicleGroup"
          reference="Vehicle"
          through="CompanyVehicleGroupRelVehicle"
          source="id"
          record={{ id: record?.id }}
          using="companyVehicleGroupId,vehicleId">
          <AutocompleteArrayInput
            label={translate('admin.vehicles')}
            optionText={(choice) => renderLabel(choice, 'vehicles')}
          />
        </ReferenceManyToManyInput>
      </Grid>
      <Grid item md={5}>
        <BooleanInput
          source="enabledDamages"
          label={translate('admin.enableDamages')}
        />
        <BooleanInput
          source="enabledBeforeQuestions"
          label={translate('admin.enableBeforeQuestions')}
        />
        <BooleanInput
          source="enabledAfterQuestions"
          label={translate('admin.enableAfterQuestions')}
        />
        <BooleanInput
          source="enabledCancellationFees"
          label={translate('admin.enableCancellation')}
        />
        <BooleanInput
          source="enabledAutoconfirmation"
          label={translate('admin.enableAutoConfirmation')}
        />
        <BooleanInput
          source="enabledExtendingBooking"
          label={translate('admin.enableExtendingBookingTime')}
        />
        <BooleanInput
          source="enabledCheckVehicleStatus"
          label={translate('admin.enableCheckVehicleStatus')}
        />
        <BooleanInput
          source="enabledCreateBookingFromApp"
          label={translate('admin.enableCreateBookingFromApp')}
        />
        <BooleanInput
          source="enabledCheckUserLevel"
          label={translate('admin.enableCheckUserLevel')}
        />
        <BooleanInput
          source="enabledBookingScheduler"
          label={translate('admin.enableBookingScheduler')}
        />
        <BooleanInput
          source="enabledVehicleCurrentPosition"
          label={translate('admin.enableVehicleCurrentPosition')}
        />
        <BooleanInput
          source="enabledRequestVehicle"
          label={translate('admin.enableRequestVehicle')}
        />
        <NumberInput
          source="minFuelLevel"
          fullWidth
          label={translate('admin.minimumFuelLevel')}
        />
        <NumberInput
          source="minBatteryLevel"
          fullWidth
          label={translate('admin.minimumBateryLevel')}
        />
      </Grid>
    </Grid>
  );
};

export const getTransformedData = (data) => {
  data.companyVehicleGroupRelVehicles =
    data[
      '@@ra-many-to-many/CompanyVehicleGroup/CompanyVehicleGroupRelVehicle/Vehicle'
    ] || [];

  if (data.minFuelLevel > 100) {
    data.minFuelLevel = 100;
  }
  if (data.minFuelLevel < 0) {
    data.minFuelLevel = 0;
  }
  if (data.minBatteryLevel > 100) {
    data.minBatteryLevel = 100;
  }
  if (data.minBatteryLevel < 0) {
    data.minBatteryLevel = 0;
  }

  return data;
};

export const CompanyVehicleGroupCreate = (props: any) => {
  const { identity } = useGetIdentity();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      transform={getTransformedData}>
      <SimpleForm
        defaultValues={{
          enabledDamages: true,
          enabledBeforeQuestions: true,
          enabledAfterQuestions: true,
          enabledCancellationFees: true,
          enabledAutoconfirmation: true,
          enabledCheckVehicleStatus: true,
          enabledCreateBookingFromApp: true,
          enabledCheckUserLevel: true,
          enabledBookingScheduler: false,
          enabledRequestVehicle: false,
          enabledVehicleCurrentPosition: false,
          enabledExtendingBooking: true,
          minFuelLevel: 20,
          minBatteryLevel: 10,
          '@@ra-many-to-many/CompanyVehicleGroup/CompanyVehicleGroupRelVehicle/Vehicle':
            identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
              ? [identity.companyId]
              : [],
        }}>
        <Form {...props} />
      </SimpleForm>
    </Create>
  );
};

export const CompanyVehicleGroupEdit = (props: any) => {
  const refresh = useRefresh();
  return (
    <Edit
      {...props}
      redirect={false}
      mutationOptions={{
        onSuccess: () => refresh(),
      }}
      mutationMode="pessimistic"
      transform={getTransformedData}>
      <SimpleForm>
        <Form {...props} />
      </SimpleForm>
    </Edit>
  );
};

export const CompanyVehicleGroupFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput label={translate('admin.search')} source="q" alwaysOn />
    </Filter>
  );
};

export const CompanyVehicleGroupList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<CompanyVehicleGroupFilter />}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label={translate('admin.name')} />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};
