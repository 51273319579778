import './index.css';

import { ApolloProvider } from '@apollo/client';
import App from './App';
import GoogleMapWrapper from './common/GoogleMapWrapper';
import createApolloClient from './libs/createApolloClient';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(isBetween);

export const url = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : location.href.includes('dmc')
  ? 'https://localhost-dmc.ngrok.app'
  : 'http://localhost:3000';

const client = createApolloClient(url + '/admin/graphql');

const root = createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <GoogleMapWrapper>
      <App client={client} />
    </GoogleMapWrapper>
  </ApolloProvider>,
);
