import {
  BillingAccount,
  BillingAccountDocument,
} from '../../../@generated/schemas';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  useDeleteBillingAccountDocumentMutation,
  useUploadBillingAccountDocumentsMutation,
} from '../../../@generated/hooks';
import { useGetList, useRecordContext } from 'react-admin';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import cookie from 'js-cookie';
import styled from 'styled-components';
import { url } from '../../..';
import { useRef } from 'react';
import { useTranslate } from '../../../locales';

export const UploadButton: React.VFC<{
  billingAccountId?: string;
  refetch: () => void;
}> = ({ refetch, billingAccountId }) => {
  const [upload, { loading }] = useUploadBillingAccountDocumentsMutation();
  const uploadInputRef = useRef<any>();
  const translate = useTranslate();

  const onChange = async (event) => {
    const files = event.target.files;

    try {
      await upload({
        variables: {
          files,
          billingAccountId,
        },
      });

      refetch();

      uploadInputRef.current.value = null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <label htmlFor="upload-file">
      <input
        ref={uploadInputRef}
        id="upload-file"
        onChange={onChange}
        multiple
        type="file"
        hidden
      />
      <Button
        style={{
          marginBottom: 20,
        }}
        disabled={loading}
        variant="contained"
        onClick={() => uploadInputRef?.current?.click()}>
        {translate('admin.addNewDocument')}
        {loading ? (
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ marginLeft: 8 }}
          />
        ) : (
          <CloudUploadIcon style={{ marginLeft: 8 }} />
        )}
      </Button>
    </label>
  );
};

const DocumentField: React.VFC<
  BillingAccountDocument & {
    deleteDocument: (id: string) => void;
    index: number;
  }
> = (props) => {
  const { id, name, originalName, deleteDocument } = props;
  const translate = useTranslate();

  return (
    <Card sx={{ width: '100%', marginBottom: 2 }}>
      <CardContent
        style={{ alignItems: 'center', display: 'flex', paddingBottom: 16 }}>
        <a
          style={{ marginRight: 8 }}
          target="_blank"
          href={`${url}/admin/billing-account-document/${name}?access_token=${cookie.get(
            'access_token',
          )}`}
          download>
          <IconButton>
            <FileDownloadIcon />
          </IconButton>
        </a>
        <DocumentName>{translate('admin.document')} {originalName}</DocumentName>
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => deleteDocument(id)}>
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export const Documents = () => {
  const record = useRecordContext<BillingAccount>();
  const [deleteDocumentMutation] = useDeleteBillingAccountDocumentMutation();

  const { data, refetch } = useGetList('BillingAccountDocument', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'orderIndex', order: 'ASC' },
    filter: {
      billingAccountId: record?.id || 'created',
    },
  });

  const deleteDocument = async (id: string) => {
    await deleteDocumentMutation({
      variables: {
        billingAccountId: record?.id,
        documentId: id,
      },
    });

    refetch();
  };

  return (
    <div style={{ width: 500 }}>
      {record?.id && (
        <UploadButton refetch={refetch} billingAccountId={record?.id} />
      )}
      {data?.map((document) => (
        <DocumentField
          key={document.id}
          {...document}
          deleteDocument={deleteDocument}
        />
      ))}
    </div>
  );
};

const DocumentName = styled.div`
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
