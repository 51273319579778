import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
} from '@mui/material';
import { FC, useRef } from 'react';
import {
  useDeleteInsuranceDocumentMutation,
  useUploadInsuranceDocumentsMutation,
} from '../../@generated/hooks';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { VehicleInsuranceDocument } from '../../@generated/schemas';
import cookie from 'js-cookie';
import styled from 'styled-components';
import { url } from '../../';
import { useGetList } from 'react-admin';
import { useTranslate } from '../../locales';

export const requiredFields = [
  'name',
  'policyNumber',
  'policyHolder',
  'postalAddress',
];

const DocumentName = styled.div`
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentField: React.VFC<
  VehicleInsuranceDocument & {
    deleteDocument: (id: string) => void;
    index: number;
  }
> = (props) => {
  const translate = useTranslate();
  const { id, name, deleteDocument, index } = props;

  return (
    <Card sx={{ width: '90%', marginBottom: 2 }}>
      <CardContent
        style={{ alignItems: 'center', display: 'flex', paddingBottom: 16 }}>
        <a
          style={{ marginRight: 8 }}
          target="_blank"
          href={`${url}/admin/insurance-document/${name}?access_token=${cookie.get(
            'access_token',
          )}`}
          download>
          <IconButton>
            <FileDownloadIcon />
          </IconButton>
        </a>
        <DocumentName>{translate('admin.document')} {index}</DocumentName>
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => deleteDocument(id)}>
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export const UploadButton: React.VFC<{
  vehicleInsuranceTypeId?: string;
  refetch: () => void;
}> = ({ refetch, vehicleInsuranceTypeId }) => {
  const translate = useTranslate();
  const [upload, { loading }] = useUploadInsuranceDocumentsMutation();
  const uploadInputRef = useRef<any>();

  const onChange = async (event) => {
    const files = event.target.files;

    try {
      await upload({
        variables: {
          files,
          vehicleInsuranceTypeId,
        },
      });

      refetch();

      uploadInputRef.current.value = null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <label htmlFor="upload-file">
      <input
        ref={uploadInputRef}
        id="upload-file"
        onChange={onChange}
        multiple
        type="file"
        hidden
      />
      <Button
        style={{
          marginBottom: 20,
        }}
        disabled={loading}
        variant="contained"
        onClick={() => uploadInputRef?.current?.click()}>
        {translate('admin.addNewDocument')}
        {loading ? (
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ marginLeft: 8 }}
          />
        ) : (
          <CloudUploadIcon style={{ marginLeft: 8 }} />
        )}
      </Button>
    </label>
  );
};

const InsuranceDetails: FC<{ vehicleInsuranceTypeId: string, children: JSX.Element | JSX.Element[] }> = ({
  children,
  vehicleInsuranceTypeId,
}) => {
  const [deleteDocumentMutation] = useDeleteInsuranceDocumentMutation();

  const { data, refetch } = useGetList('VehicleInsuranceDocument', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'orderIndex', order: 'ASC' },
    filter: {
      vehicleInsuranceTypeId: vehicleInsuranceTypeId || 'created',
    },
  });

  const deleteDocument = async (id: string) => {
    await deleteDocumentMutation({
      variables: {
        vehicleDocumentId: id,
      },
    });

    refetch();
  };

  return (
    <Grid container spacing={8}>
      <Grid item md={5}>
        {children}
      </Grid>
      <Grid item md={7}>
        {vehicleInsuranceTypeId && (
          <UploadButton
            refetch={refetch}
            vehicleInsuranceTypeId={vehicleInsuranceTypeId}
          />
        )}
        {data?.map((document, key) => (
          <DocumentField
            key={document.id}
            index={key + 1}
            {...document}
            deleteDocument={deleteDocument}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default InsuranceDetails;
