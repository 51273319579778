import {
  TextInput as AdminTextInput,
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceInput,
  TextField,
  useGetIdentity,
} from 'react-admin';

import { Chip } from '@mui/material';
import CustomPagination from '../../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { UserRole } from '../../@generated/schemas';
import { gql } from '@apollo/client';
import { useTranslate } from '../../locales';

export const SharedVehicleGroupsFilter = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <AdminTextInput
        label={translate('admin.search')}
        source="q"
        key="search"
        alwaysOn
      />
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <ReferenceInput
          reference="Company"
          source="companies.some.companyId"
          label={translate('admin.company')}>
          <AutocompleteInput
            optionText="name"
            style={{width: 400}}
            label={translate('admin.company')}
          />
        </ReferenceInput>
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <ReferenceInput
          reference="Vehicle"
          source="vehicles.some.vehicleId"
          label={translate('admin.vehicle')}>
          <AutocompleteInput
            style={{width: 400}}
            optionText={(choice: any) => `${choice.plateNumber} (${choice?.vin})`}
            label={translate('admin.vehicle')}
          />
        </ReferenceInput>
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <ReferenceInput
          reference="User"
          source="users.some.userId"
          label={translate('admin.user')}>
          <AutocompleteInput
            style={{width: 400}}
            optionText={(option) => {
              return `${option.firstName || ''} ${
                option.lastName || ''
              } (${option.email})`;
            }}
            label={translate('admin.user')}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};

export const SharedVehicleGroupList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  const defaultFilter = {
    deletedAt: {
      equals: null,
    },
    OR: [
      {
        companies:
          identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
            ? {
                some: {
                  companyId: {
                    equals: identity.companyId,
                  },
                },
              }
            : {},
      },
      {
        vehicles:
          identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
            ? {
                some: {
                  vehicle: {
                    is: {
                      user: {
                        is: {
                          companyId: {
                            equals: identity.companyId,
                          },
                        },
                      },
                    },
                  },
                },
              }
            : {},
      },
      {
        users:
          identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
            ? {
                some: {
                  user: {
                    is: {
                      companyId: {
                        equals: identity.companyId,
                      },
                    },
                  },
                },
              }
            : {},
      },
    ],
  };

  return (
    <List
      {...props}
      filters={<SharedVehicleGroupsFilter />}
      filter={defaultFilter}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label={translate('admin.name')} />
        <FunctionField
          source="vehicles"
          label={translate('admin.vehicles')}
          render={(record) => <Chip label={record.vehicles.length} />}
        />
        <FunctionField
          source="users"
          label={translate('admin.users')}
          render={(record) => <Chip label={record.users.length} />}
        />
        <FunctionField
          source="companies"
          label={translate('admin.companies')}
          render={(record) => <Chip label={record.companies.length} />}
        />
        <BooleanField
          source="ownerBehavior"
          label={translate('admin.ownerBehavior')}
        />
        <BooleanField source="isPrivate" label={translate('admin.isPrivate')} />
        <BooleanField source="enabledSendLateMessages" label={translate('admin.enabledSendLateMessages')} />
        <BooleanField
          source="discountIncludesInsurance"
          label={translate('admin.discountIncludesInsurance')}
        />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};

export const SharedVehicleGroup: ResourceView = {
  resource: 'SharedVehicleGroup',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment SharedVehicleGroupFragment on SharedVehicleGroup {
          vehicles {
            id
            vehicle {
              id
              vin
              plateNumber
              name
              brandType {
                name
              }
              modelType {
                name
              }
            }
          }
          companies {
            id
            company {
              id
              name
            }
          }
          users {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment SharedVehicleGroupFragment on SharedVehicleGroup {
          vehicles {
            id
            vehicle {
              id
              vin
              plateNumber
              name
              brandType {
                name
              }
              modelType {
                name
              }
            }
          }
          companies {
            id
            company {
              id
              name
            }
          }
          users {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
      `,
    },
  },
};

export const SharedVehicleGroupRelVehicle: ResourceView = {
  resource: 'SharedVehicleGroupRelVehicle',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment Vehicle on Vehicle {
          vehicle {
            id
            vin
            plateNumber
            name
            brandType {
              name
            }
            modelType {
              name
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment Vehicle on Vehicle {
          vehicle {
            id
            vin
            plateNumber
            name
            brandType {
              name
            }
            modelType {
              name
            }
          }
        }
      `,
    },
  },
};
