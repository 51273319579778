import {
  AutocompleteInput,
  Datagrid,
  DateTimeInput,
  Filter,
  FunctionField,
  Link,
  List,
  NumberField,
  ReferenceInput,
  downloadCSV,
  useGetIdentity,
} from 'react-admin';

import { ResourceView } from '@ra-data-prisma/dataprovider';
import { UserRole } from '../../../@generated/schemas';
import { gql } from '@apollo/client';
import jsonExport from 'jsonexport/dist';
import { useTranslate } from '../../../locales';

export const VehicleReportFilter = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  const defaultUserFilter = {
    companyId:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? {
          equals: identity.companyId
        }
        : {},
  };

  return (
    <Filter {...props}>
      <ReferenceInput source="vehicleId" reference="Vehicle" alwaysOn>
        <AutocompleteInput
          label={translate('admin.vehicle')}
          optionText={(choice: any) => `${choice?.vin} (${choice?.name ? `${choice?.name}, ` : ''}${choice?.plateNumber}, ${choice?.brandType?.name})`}
          style={{ width: 300 }}
        />
      </ReferenceInput>
      <ReferenceInput source="ownerId" reference="User" alwaysOn filter={defaultUserFilter}>
        <AutocompleteInput
          label={translate('admin.user')}
          optionText={(choice: any) =>
            `${choice?.firstName} ${choice?.lastName} (${choice?.email})`
          }
          style={{ width: 300 }}
        />
      </ReferenceInput>
      {identity?.userRole && identity?.userRole === UserRole.SuperAdmin && (
        <ReferenceInput source="companyId" reference="Company" alwaysOn>
          <AutocompleteInput
            label={translate('admin.company')}
            optionText={(choice: any) =>
              `${choice?.name}`
            }
            style={{ width: 300 }}
          />
        </ReferenceInput>
      )}
      <DateTimeInput source="startDate" label={translate('admin.startDate')} alwaysOn />
      <DateTimeInput source="endDate" label={translate('admin.endDate')} alwaysOn />
    </Filter>
  )
};

const customExporter = (items) => {
  const listForExport = items.map((row) => ({
    Vehicle: `${row.vehicle.vin} (${row.vehicle.name ? `${row.vehicle.name}, ` : ''}${row.vehicle.brandType.name}, ${row.vehicle.modelType.name})`,
    User: row?.vehicle?.user?.email,
    Company: row?.vehicle?.company?.name,
    'Total bookings': row.bookingCount,
    'Total km': row.totalKm,
    'Total income': row.totalIncome,
    'Total vehicle owner': row.totalVehicleOwner,
  }));

  jsonExport(listForExport, { rowDelimiter: ';' }, (err, csv) =>
    downloadCSV(csv, 'Vehicle report'),
  );
};

export const VehicleReportList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const defaultFilter = {
    // vehicle:
    //   identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
    //     ? {
    //       is: {
    //         user: {
    //           is: {
    //             companyId: {
    //               equals: identity.companyId
    //             },
    //           },
    //         },
    //       },
    //     }
    //     : {},
  };

  return (
    <List
      {...props}
      exporter={customExporter}
      filter={defaultFilter}
      filters={<VehicleReportFilter />}
      pagination={null}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          source="vehicle"
          label={translate('admin.vehicle')}
          render={(row) => {
            return `${row.vehicle.vin} (${row.vehicle.name ? `${row.vehicle.name}, ` : ''}${row.vehicle.brandType.name}, ${row.vehicle.modelType.name})`;
          }}
        />
        <FunctionField
          source="user"
          label={translate('admin.user')}
          render={(row) => {
            return (
              <Link to={`/User/${row?.vehicle?.user?.id}`}>
                {row?.vehicle?.user?.email}
              </Link>
            );
          }}
        />
        <FunctionField
          source="company"
          label={translate('admin.company')}
          render={(row) => {
            return (
              <Link to={`/Company/${row?.vehicle?.company?.id}`}>
                {row?.vehicle?.company?.name}
              </Link>
            );
          }}
        />
        <NumberField source="bookingCount" label={translate('admin.bookingCount')} />
        <FunctionField source="totalKm" render={(row) => `${row.totalKm}km`} label={translate('admin.totalKm')} />
        <FunctionField
          source="totalIncome"
          label={translate('admin.totalIncome')}
          render={(row) => `CHF ${row.totalIncome}`}
        />
        <FunctionField
          source="totalVehicleOwner"
          label={translate('admin.totalVehicleOwner')}
          render={(row) => `CHF ${row.totalVehicleOwner}`}
        />
      </Datagrid>
    </List>
  );
};

export const VehicleReport: ResourceView = {
  resource: 'VehicleReport',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment VehicleReportFragment on VehicleReport {
          vehicle {
            id
            vin
            name
            user {
              id
              email
            }
            company {
              id
              name
              contactPersonEmail
            }
            brandType {
              id
              name
            }
            modelType {
              id
              name
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment VehicleReportFragment on VehicleReport {
          vehicle {
            id
            vin
            name
            user {
              id
              email
            }
            company {
              id
              name
              contactPersonEmail
            }
            brandType {
              id
              name
            }
            modelType {
              id
              name
            }
          }
        }
      `,
    },
  },
};
