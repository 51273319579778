import {
  BookingQuestion,
  BookingQuestionKind,
  BookingQuestionType,
} from '../../@generated/schemas';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  useBookingQuestionsQuery,
  useUpdateBookingQuestionMutation,
} from '../../@generated/hooks';
import { useCreate, useRecordContext } from 'react-admin';
import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TABS } from './tabs';
import { TabPanel } from '@mui/lab';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTranslate } from '../../locales';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 24px;
`;

const DEFAULT_QUESTION = {
  id: undefined,
  kind: BookingQuestionKind.Boolean,
  titleEn: '',
  titleDe: '',
  titleIt: '',
  titleFr: '',
  defaultValue: '',
  requiredValue: '',
  isEnabled: true,
  isRequired: false,
};

const BookingQuestionTable = (props) => {
  const { data, title, type, userId, refetch } = props;

  const [open, setOpen] = useState(false);
  const [newData, setNewData] = useState<any>(DEFAULT_QUESTION);
  const onClose = () => {
    setOpen(false);
    setNewData(DEFAULT_QUESTION);
  };
  const translate = useTranslate();
  const [create] = useCreate();
  const [update] = useUpdateBookingQuestionMutation();

  const handleSave = async () => {
    const _newData = {
      kind: newData.kind,
      type,
      userId,
      orderIndex: data.length,
      isEnabled: newData.isEnabled,
      isRequired: newData.isRequired,
      requiredValue: (newData.requiredValue || '').toString(),
      defaultValue: (newData.defaultValue || '').toString(),
      title: JSON.stringify({
        en: newData.titleEn,
        de: newData.titleDe,
        it: newData.titleIt,
        fr: newData.titleFr,
      }),
    };

    if (newData.id) {
      await update({
        variables: {
          userId,
          bookingQuestionId: newData.id,
          data: {
            ..._newData,
            orderIndex: newData.orderIndex,
          },
        },
      });
    } else {
      await create(
        'BookingQuestion',
        {
          data: {
            ..._newData,
            user: userId,
          },
        },
        {
          returnPromise: true,
        },
      );
    }

    onClose();
    refetch();
  };

  const updateItem = async (
    bookingQuestionId: string,
    param: string,
    value: any,
  ) => {
    await update({
      variables: {
        userId,
        bookingQuestionId,
        data: {
          [param]: value,
        },
      },
    });

    refetch();
  };

  const move = async (id: string, newOrder: number) => {
    const _data = data
      .map((item) => {
        if (item.id === id) {
          return {
            ...item,
            orderIndex: item.orderIndex + newOrder,
          };
        }
        return item;
      })
      .sort((itemA, itemB) => itemA.orderIndex - itemB.orderIndex)
      .map((item, orderIndex) => ({ ...item, orderIndex }));

    const promisedUpdate = _data.map(
      async (data: any) =>
        await update({
          variables: {
            userId,
            bookingQuestionId: data.id,
            data: {
              orderIndex: data.orderIndex,
            },
          },
        }),
    );

    await Promise.all(promisedUpdate);

    refetch();
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <Row>
        <Typography variant="h6">{title}</Typography>
        <Button
          variant="contained"
          size="small"
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpen(true)}>
          <AddIcon /> Add new
        </Button>
      </Row>
      <Table
        sx={{ minWidth: 650 }}
        style={{ maxHeight: '5wh' }}
        aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('admin.order')}</TableCell>
            <TableCell width="30%">{translate('admin.title')}</TableCell>
            <TableCell>{translate('admin.enabled')}</TableCell>
            <TableCell>{translate('admin.required')}</TableCell>
            <TableCell>{translate('admin.requiredValue')}</TableCell>
            <TableCell>{translate('admin.defaultValue')}</TableCell>
            <TableCell>{translate('admin.kind')}</TableCell>
            <TableCell align="right" width="20%">
              {translate('admin.actions')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .sort((itemA, itemB) => itemA.orderIndex - itemB.orderIndex)
            .map((bookingQuestion, key) => {
              const isDefault =
                bookingQuestion.bookingQuestionId || !bookingQuestion.userId;
              return (
                <TableRow key={`${bookingQuestion.id}-${key}`}>
                  <TableCell component="th" scope="row">
                    <IconButton
                      disabled={bookingQuestion.orderIndex === 0}
                      onClick={() => move(bookingQuestion.id, -1.5)}>
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => move(bookingQuestion.id, 1.5)}
                      disabled={bookingQuestion.orderIndex === data.length - 1}>
                      <ArrowDownwardIcon />
                    </IconButton>
                    {isDefault && <Chip color="primary" label={translate('admin.default')} />}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <div>EN: {bookingQuestion.parsedTitle?.en}</div>
                    <div>DE: {bookingQuestion.parsedTitle?.de}</div>
                    <div>IT: {bookingQuestion.parsedTitle?.it}</div>
                    <div>FR: {bookingQuestion.parsedTitle?.fr}</div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={bookingQuestion.isEnabled}
                      onChange={() =>
                        updateItem(
                          bookingQuestion.id,
                          'isEnabled',
                          !bookingQuestion.isEnabled,
                        )
                      }
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={bookingQuestion.isRequired}
                      onChange={() =>
                        updateItem(
                          bookingQuestion.id,
                          'isRequired',
                          !bookingQuestion.isRequired,
                        )
                      }
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {bookingQuestion.kind === BookingQuestionKind.Boolean && (
                      <Checkbox
                        checked={bookingQuestion.requiredValue === 'true'}
                        onChange={() =>
                          updateItem(
                            bookingQuestion.id,
                            'requiredValue',
                            bookingQuestion.requiredValue === 'true'
                              ? 'false'
                              : 'true',
                          )
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {bookingQuestion.defaultValue}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {bookingQuestion.kind}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setNewData({
                          ...bookingQuestion,
                          titleEn: bookingQuestion.parsedTitle.en,
                          titleDe: bookingQuestion.parsedTitle.de,
                          titleIt: bookingQuestion.parsedTitle.it,
                          titleFr: bookingQuestion.parsedTitle.fr,
                        });
                        setOpen(true);
                      }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        updateItem(
                          bookingQuestion.id,
                          'deletedAt',
                          dayjs().toDate(),
                        )
                      }
                      aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContent>
          <IconButton
            style={{ position: 'absolute', right: 18, top: 10 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
          {translate('admin.addNew')} {type}
          </Typography>

          <Row style={{ marginBottom: 20 }}>
            <FormLabel style={{ marginBottom: 8, width: 85 }}>Kind</FormLabel>
            <Select
              style={{ width: '100%' }}
              value={newData.kind}
              variant="outlined"
              size="small"
              onChange={(event) =>
                setNewData({
                  ...newData,
                  defaultValue: '',
                  kind: event.target.value as BookingQuestionKind,
                })
              }>
              <MenuItem key="boolean" value={BookingQuestionKind.Boolean}>
                Boolean
              </MenuItem>
              <MenuItem key="string" value={BookingQuestionKind.String}>
                String
              </MenuItem>
              <MenuItem key="rating" value={BookingQuestionKind.Rating}>
                Rating
              </MenuItem>
            </Select>
          </Row>

          <div style={{ marginBottom: 20 }}>
            <FormLabel style={{ marginBottom: 8, width: 120 }}>
              {translate('admin.enabled')}
            </FormLabel>
            <Checkbox
              style={{ marginLeft: 17 }}
              checked={newData.isEnabled}
              onChange={() =>
                setNewData({
                  ...newData,
                  isEnabled: !newData.isEnabled,
                })
              }
            />
          </div>

          <div style={{ marginBottom: 20 }}>
            <FormLabel style={{ marginBottom: 8, width: 120 }}>
              {translate('admin.required')}
            </FormLabel>
            <Checkbox
              style={{ marginLeft: 12 }}
              checked={newData.isRequired}
              onChange={() =>
                setNewData({
                  ...newData,
                  isRequired: !newData.isRequired,
                })
              }
            />
          </div>

          {newData.kind === BookingQuestionKind.Boolean && (
            <div style={{ marginBottom: 20 }}>
              <FormLabel style={{ marginBottom: 8, width: 120 }}>
                {translate('admin.requiredValue')}
              </FormLabel>
              <Checkbox
                style={{ marginLeft: 12 }}
                checked={newData.requiredValue === 'true'}
                onChange={() => {
                  setNewData({
                    ...newData,
                    requiredValue:
                      newData.requiredValue === 'true' ? 'false' : 'true',
                  });
                }}
              />
            </div>
          )}

          {newData.kind === BookingQuestionKind.String && (
            <div style={{ marginBottom: 40 }}>
              <FormLabel>{translate('admin.defaultValue')}</FormLabel>
              <TextField
                style={{ marginTop: 12 }}
                fullWidth
                variant="outlined"
                size="small"
                value={newData.defaultValue}
                onChange={(event) =>
                  setNewData({
                    ...newData,
                    defaultValue: event.target.value,
                  })
                }
              />
            </div>
          )}

          {newData.kind === BookingQuestionKind.Boolean && (
            <div style={{ marginBottom: 40 }}>
              <FormLabel>{translate('admin.defaultValue')}</FormLabel>
              <Checkbox
                style={{ marginLeft: 12 }}
                checked={newData.defaultValue === 'true'}
                onChange={() =>
                  setNewData({
                    ...newData,
                    defaultValue:
                      newData.defaultValue === 'true' ? 'false' : 'true',
                  })
                }
              />
            </div>
          )}

          <div style={{ marginBottom: 20 }}>
            <FormLabel>Title EN</FormLabel>
            <TextField
              style={{ marginTop: 12 }}
              fullWidth
              variant="outlined"
              size="small"
              value={newData.titleEn}
              onChange={(event) =>
                setNewData({
                  ...newData,
                  titleEn: event.target.value,
                })
              }
            />
          </div>

          <div style={{ marginBottom: 20 }}>
            <FormLabel>{translate('admin.title')} DE</FormLabel>
            <TextField
              style={{ marginTop: 12 }}
              fullWidth
              variant="outlined"
              size="small"
              value={newData.titleDe}
              onChange={(event) =>
                setNewData({
                  ...newData,
                  titleDe: event.target.value,
                })
              }
            />
          </div>

          <div style={{ marginBottom: 20 }}>
            <FormLabel>{translate('admin.title')} IT</FormLabel>
            <TextField
              style={{ marginTop: 12 }}
              fullWidth
              variant="outlined"
              size="small"
              value={newData.titleIt}
              onChange={(event) =>
                setNewData({
                  ...newData,
                  titleIt: event.target.value,
                })
              }
            />
          </div>

          <div style={{ marginBottom: 20 }}>
            <FormLabel>{translate('admin.title')} FR</FormLabel>
            <TextField
              style={{ marginTop: 12 }}
              fullWidth
              variant="outlined"
              size="small"
              value={newData.titleFr}
              onChange={(event) =>
                setNewData({
                  ...newData,
                  titleFr: event.target.value,
                })
              }
            />
          </div>

          <Button
            variant="contained"
            onClick={handleSave}
            style={{ float: 'right', marginTop: 20 }}>
            {translate('admin.save')}
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
};

export const BookingQuestions = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { data, loading, refetch } = useBookingQuestionsQuery({
    variables: {
      userId: record?.id as string,
    },
    skip: !record?.id,
  });

  const [bookingQuestions, setBookingQuestions] = useState<BookingQuestion[]>(
    [],
  );

  useEffect(() => {
    if (!loading && data?.bookingQuestions.length > 0) {
      setBookingQuestions((data?.bookingQuestions || []) as BookingQuestion[]);
    }
  }, [loading, data]);

  return (
    <TabPanel value={TABS.bookingQuestions}>
      <BookingQuestionTable
        title={translate('admin.preBookingQuestions')}
        refetch={refetch}
        userId={record.id}
        type={BookingQuestionType.Pre}
        data={bookingQuestions.filter(
          (bookingQuestion) => bookingQuestion.type === BookingQuestionType.Pre,
        )}
      />
      <BookingQuestionTable
        title={translate('admin.postBookingQuestions')}
        userId={record.id}
        refetch={refetch}
        type={BookingQuestionType.Post}
        data={bookingQuestions.filter(
          (bookingQuestion) =>
            bookingQuestion.type === BookingQuestionType.Post,
        )}
      />
    </TabPanel>
  );
};
